import {AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material";
import {AddAgentsComponent} from "./add-agents.component";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {DataSource} from "@angular/cdk/table";
// import {Observable} from "rxjs/Rx";
import {Observable} from "rxjs/Observable";
import {AgentService} from "../shared/services/agent.service";
import {Agent} from "../shared/models/agent.model";

@Component({
	selector: 'agents',
	templateUrl: './agents.component.html',
	styleUrls: ["./agents-styles.css"]
})
export class AgentsComponent implements OnInit {
	displayedColumns = ["name", "email", "office", "cell", "company", "rating"];
	agentDatabase = new AgentDatabase();
	dataSource: AgentDataSource | null;
	addAgentsDialog: any;
	editAgentsDialog: any;
	@Input() search: string;
	dataChange: BehaviorSubject<AgentData[]> = new BehaviorSubject<AgentData[]>([]);

	get data(): AgentData[] {
		return this.dataChange.value;
	}

	emptyAgent = {
		name: undefined,
		email: undefined,
		office: undefined,
		cell: undefined,
		company: undefined
	};

	constructor(public dialog: MatDialog,
				private agentService: AgentService) {
	}

	ngOnInit() {
		this.dataSource = new AgentDataSource(this);
		// TODO: Fix eventually. Without this line the table doesn't load until you click "Agents" a second time.
		this.searchAgents();
	}

	openAddAgents() {
		this.addAgentsDialog = this.dialog.open(AddAgentsComponent, {disableClose: true, maxWidth: "30vw"});
	}

	searchAgents() {
		var arr = [];
		// if (!this.search)
		//   this.search = "";
		this.agentService.get(this.search).subscribe(val => {
			if (!val) return;
			for (var i = 0; i < Object.keys(val).length; i++) {
				arr.push(val[i]);
			}
			this.dataChange.next(arr)
		});
	}

	searchAgentsEnter() {
		if (!!this.search)
			this.searchAgents();
	}

	editAgent(agent) {
		this.editAgentsDialog = this.dialog.open(AddAgentsComponent, {disableClose: true, data: agent, maxWidth: "30vw"});

		this.editAgentsDialog.afterClosed().subscribe(value => {
			this.searchAgentsEnter();
		})
	}
}

export interface AgentData {
	name: string;
	email: string;
	office: string;
	cell: string;
	company: string;
}

export class AgentDatabase {
	/** Stream that emits whenever the data has been modified. */
	dataChange: BehaviorSubject<AgentData[]> = new BehaviorSubject<AgentData[]>([]);

	get data(): AgentData[] {
		return this.dataChange.value;
	}

	constructor() {
	}
}

export class AgentDataSource extends DataSource<any> {
	constructor(private _agentsComponent: AgentsComponent) {
		super();
	}

	/** Connect function called by the table to retrieve one stream containing the data to render. */
	connect(): Observable<AgentData[]> {
		return this._agentsComponent.dataChange;
	}

	disconnect() {
	}
}
