import {Injectable} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {Headers, Http, RequestOptions} from "@angular/http";

@Injectable()

export class AddressService {
	constructor(private http: Http) {
	}

	private setHeaders(): Headers {
		const headersConfig = {
			'Content-Type': 'application/json',
			'Accept': 'application/json'

		};

		return new Headers(headersConfig);
	}

	getAddresses(search: string): Observable<any> {
		//let headeras = new Headers({"Access-Control-Allow-Origin": "*"});
		//let option = new RequestOptions(headers: headeras);
		return this.http.get("https://maps.googleapis.com/maps/api/place/nearbysearch/json?location=-33.8670522,151.1957362&radius=500&type=restaurant&keyword=cruise&key=AIzaSyAho-IV1qeWBNZGUmxAnUCjIAp4VumpNJg",
			{headers: this.setHeaders()})
			.catch(err => err.json())
			.map((res: Response) => res.json())
	}
}
