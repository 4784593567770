import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {SettingsComponent} from './settings.component';
import {AuthGuard, SharedModule} from '../shared';
import {CompaniesComponent} from "./companies/companies.component";
import {AddCompanyComponent} from "./companies/add-company.component";
import {InspectorsComponent} from "./inspectors/inspectors.component";
import {AddInspectorComponent} from "./inspectors/add-inspector.component";

const settingsRouting: ModuleWithProviders = RouterModule.forChild([
	{
		path: 'settings',
		component: SettingsComponent,
		canActivate: [AuthGuard]
	},
    {
        path: 'companies',
        component: CompaniesComponent,
        canActivate: [AuthGuard]
    },
    {
        path: 'inspectors',
        component: InspectorsComponent,
        canActivate: [AuthGuard]
    }
]);

@NgModule({
	imports: [
		SharedModule,
		settingsRouting
	],
	declarations: [
		SettingsComponent,
		CompaniesComponent,
        InspectorsComponent
	],
    entryComponents: [
        AddInspectorComponent,
        AddCompanyComponent
    ]
})
export class SettingsModule {
}
