import {AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {MatDialog, MatSnackBar} from "@angular/material";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {DataSource} from "@angular/cdk/table";
// import {Observable} from "rxjs/Rx";
import {Observable} from "rxjs/Observable";
import * as moment from "moment";
import {AppointmentService} from "../../shared/services/appointment.service";
import {date} from "ng2-validation/dist/date";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Appointment} from "../../shared/models/appointment.model";
import {ConfigService} from "app/shared";
import {StringService} from "../../shared/services/string.service";
import {UserService} from "../../shared/services/user.service";
import {InspectorService} from "../../shared/services";

@Component({
	selector: 'inspectors-overview',
	templateUrl: './overview.component.html',
	styleUrls: ["./reports.styles.css"]
})
export class InspectorsOverviewComponent implements OnInit {
	HST = 0.13;
	WSIB = .0296;
	reportForm: FormGroup;
	startDateCtrl = new FormControl();
	endDateCtrl = new FormControl();
	inspectorNameCtrl = new FormControl();
	checked = false;
	inspectors = [];
	appointments: any;
	subtotal = 0;
	hst = 0;
	total = 0;
	wsib = 0;
	inspectorTotal = 0;
	inspectionTypesResultsCurrent: any = {};
	inspectionTypesResultsPrevious: any = {};
	inspectionTypesResultsFull: any = {};
	inspectionTypesTable = [];
	resultsDefault = {
		totalInspections: 0,
		grossValue: 0,
		adjustment: 0,
		tax: 0,
		total: 0,
		base: 0,
		travel: 0,
		size: 0,
		holi: 0,
		misc: 0,
		subtotal: 0,
		hst: 0,
		wsib: 0,
        requested: 0,
		totalToInspector: 0
	};
	overviewResultsCurrent: any = {};
	overviewResultsPrevious: any = {};

	constructor(private appointmentService: AppointmentService,
				private snackBar: MatSnackBar,
				private stringService: StringService,
				private configService: ConfigService,
				private inspectorService: InspectorService,
				private fb: FormBuilder) {
		this.reportForm = this.fb.group({
			startDateCtrl: new Date(),
			endDateCtrl: new Date(),
			inspectorNameCtrl: this.inspectors[0]
		})
	}

	runReport() {
		let search = {
                startDate: new Date(this.reportForm.value.startDateCtrl.setHours(0,0,0,0)).toISOString(),
                endDate: new Date(this.reportForm.value.endDateCtrl.setHours(23,59,59,0)).toISOString(),
				inspector: this.reportForm.value.inspectorNameCtrl
			},
			start = new Date(search.startDate),
			end = new Date(search.endDate),
			searchPrev, searchFull, i, j;
		this.inspectionTypesTable = [];
		start = new Date(start.setFullYear(start.getFullYear() - 1));
		end = new Date(end.setFullYear(end.getFullYear() - 1));
		searchPrev = {
			startDate: start,
			endDate: end,
			inspector: this.reportForm.value.inspectorNameCtrl
		};
		searchFull = {
			startDate: start,
			endDate: this.reportForm.value.endDateCtrl,
			inspector: this.reportForm.value.inspectorNameCtrl
		};
		Observable.combineLatest(
			this.appointmentService.getTypeCounts(search),
			this.appointmentService.getTypeCounts(searchPrev),
			this.appointmentService.getTypeCounts(searchFull),
            this.appointmentService.getInspectorsOverview(search),
            this.appointmentService.getInspectorsOverview(searchPrev)
		).subscribe(values => {
			function getTypeCount(array, inspectionType) {
				for (j = 0; j < array.length; j++) {
					if (array[j]._id === inspectionType)
						return array[j].count;
				}
				return 0;
			};
			this.inspectionTypesResultsCurrent = values[0];
			this.inspectionTypesResultsPrevious = values[1];
			this.inspectionTypesResultsFull = values[2];
			for (i = 0; i < this.inspectionTypesResultsFull.length; i++) {
				this.inspectionTypesTable.push({
					inspectionType: this.inspectionTypesResultsFull[i]._id ?
						this.inspectionTypesResultsFull[i]._id : "Unspecified"
				});
				this.inspectionTypesTable[i].current = getTypeCount(this.inspectionTypesResultsCurrent,
					this.inspectionTypesResultsFull[i]._id);
				this.inspectionTypesTable[i].previous = getTypeCount(this.inspectionTypesResultsPrevious,
					this.inspectionTypesResultsFull[i]._id);
			};
            this.overviewResultsCurrent = values[3][0] ? values[3][0] : this.resultsDefault;
            this.overviewResultsPrevious = values[4][0] ? values[4][0] : this.resultsDefault;
            this.snackBar.open(this.stringService.reportRunSuccessfully, null, {duration: this.configService.getToastTimeout()});
		});
	}

	ngOnInit() {
		this.inspectorService.get()
			.subscribe(val => {
			    let res : any = val;
				res.forEach(x => {
					this.inspectors.push({
                        _id: x._id,
                        name: x.name
					});
					return;
				});
			});
	}
}


