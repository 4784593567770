import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';

import {Errors, UserService} from '../shared';
import {CompanyService, InspectorService} from "../shared/services";
import {Observable} from "rxjs/Observable";

@Component({
	selector: 'auth-page',
	templateUrl: './auth.component.html'
})
export class AuthComponent implements OnInit {
	authType: String = '';
	title: String = '';
	errors: Errors = new Errors();
	isSubmitting = false;
	authForm: FormGroup;

	constructor(private route: ActivatedRoute,
				private router: Router,
				private userService: UserService,
				private companyService: CompanyService,
				private inspectorService: InspectorService,
				private fb: FormBuilder) {
		// use FormBuilder to create a form group
		this.authForm = this.fb.group({
			'email': ['', Validators.required],
			'password': ['', Validators.required],
			"companyName": [''],
			"inspectorName": [''],
			"inspectorEmail": [''],
		});
	}

	ngOnInit() {
		this.route.url.subscribe(data => {
			// Get the last piece of the URL (it's either 'login' or 'register')
			this.authType = data[data.length - 1].path;
			// Set a title for the page accordingly
			this.title = (this.authType === 'login') ? 'Sign in' : 'Sign up';
			// add form control for username if this is the register page
			if (this.authType === 'register') {
				this.authForm.addControl('username', new FormControl());
				this.authForm.controls["inspectorName"].setValidators([Validators.required])
				this.authForm.controls["companyName"].setValidators([Validators.required])
				this.authForm.controls["inspectorEmail"].setValidators([Validators.email])
			}
		});
	}

	submitForm() {
		this.isSubmitting = true;
		this.errors = new Errors();

		let credentials = this.authForm.value;
		credentials.email = credentials.email.toLowerCase();
		this.userService
			.attemptAuth(this.authType, credentials)
			.subscribe(
				data => {
				    if (this.authType === "login") {
                        this.router.navigateByUrl('/book')
                    } else {
                        Observable.forkJoin(
                            this.companyService.save({name: this.authForm.get("companyName").value}),
                            this.inspectorService.save({
                                name: this.authForm.get("inspectorName").value,
                                email: this.authForm.get("inspectorEmail").value
                            })
                        ).subscribe(results => {
                            this.router.navigateByUrl('/book')
                        });
                    }
                },
				err => {
					this.errors = err;
					this.isSubmitting = false;
				}
			);
	}
}
