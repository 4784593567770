import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AuthGuard, SharedModule} from '../shared';
import {errorComponent} from "../shared/error.component";
import {InfoDialogComponent} from "../infoDialog/info-dialog.component";
import {AppointmentsComponent} from "./appointments.component";

const appointmentsRouting: ModuleWithProviders = RouterModule.forChild([
	{
		path: 'appointments',
		component: AppointmentsComponent,
		canActivate: [AuthGuard]
	}
]);

@NgModule({
	imports: [
		appointmentsRouting,
		SharedModule
	],
	declarations: [
		AppointmentsComponent
	],
	providers: []
})
export class AppointmentsModule {

}
