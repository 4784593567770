import {Component, OnInit} from '@angular/core';

import {User} from '../models';
import {UserService} from '../services';
import {Router} from "@angular/router";

@Component({
	selector: 'layout-header',
	templateUrl: './header.component.html'
})
export class HeaderComponent implements OnInit {
	constructor(private userService: UserService,
				private router: Router) {
	}

	currentUser: User;

	logout() {
		this.userService.purgeAuth();
		this.router.navigateByUrl('/login');
	}

	ngOnInit() {
		this.userService.currentUser.subscribe(
			(userData) => {
				this.currentUser = userData;
			}
		)
	}
}
