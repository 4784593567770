import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup} from '@angular/forms';
import {Router} from '@angular/router';

import {User, UserService} from '../shared';
import {StringService} from "../shared/services/string.service";
import {ConfigService} from "../shared/services/config.service";
import {MatSnackBar} from "@angular/material";
import {CompanyService} from "../shared/services";

@Component({
	selector: 'settings-page',
	templateUrl: './settings.component.html'
})
export class SettingsComponent {
	user: User = new User();
	errors: Object = {};
	isSubmitting = false;
	values: any = {};

	constructor(private router: Router, private userService: UserService, private fb: FormBuilder,
				private stringService: StringService, private configService: ConfigService, public snackBar: MatSnackBar,
                private companyService: CompanyService) {
	}
}
