import {Injectable} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {Headers, Http, RequestOptions} from "@angular/http";
import {MatDialog, MatSnackBar} from "@angular/material";
import {ConfigService} from "./config.service";
import {InfoDialogComponent} from "../../infoDialog/info-dialog.component";
import {StringService} from "./string.service";

@Injectable()
export class ErrorService {
	constructor(public dialog: MatDialog,
				private configService: ConfigService,
				private stringService: StringService) {
	}

	showError(message?: string) {
		switch (message) {
			default:
				message = this.stringService.errorUnexpected;
		}
		this.dialog.open(InfoDialogComponent, {
			data: {title: "Error!", info: message}
		})
	}
}
