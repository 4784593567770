import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/map';
import {any} from "codelyzer/util/function";
import {Component, Inject, OnInit} from "@angular/core";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";
import {Company} from "../shared/models/company.model";
import {CompanyService} from "../shared/services";
import * as moment from "moment";
import {InfoDialogComponent} from "../infoDialog/info-dialog.component";

@Component({
	selector: 'invoice-view',
	templateUrl: './invoice.component.html',
	styleUrls: ["./invoice.css"]
})
export class InvoiceComponent implements OnInit {

    company = new Company();
    billToName;
    billToEmail;
    invoiceDateDisplay;
    appointmentDateDisplay;
    paymentDateDisplay;

	constructor(
        private companyService: CompanyService,
	    @Inject(MAT_DIALOG_DATA) public data: any,
        public dialogRef: MatDialogRef<InfoDialogComponent>
    ) {}

    ngOnInit() {
	    this.companyService.getLogos()
            .subscribe(vals => {
                vals.forEach(x => {
                    if (x._id === this.data.company) this.company = x;
                });
            });
	    if (this.data.billTo === "Agent") {
	        this.billToName = this.data.agent.name;
	        this.billToEmail = this.data.agent.email;
        } else if (this.data.billTo === "Client") {
            this.billToName = this.data.clientName;
            this.billToEmail = this.data.clientEmail;
        } else {
            this.billToName = this.data.billToName;
            this.billToEmail = this.data.billToEmail;
        }
        let date = this.data.invoiceDate ? this.data.invoiceDate : new Date();
        this.invoiceDateDisplay = moment(date).format("MMM Do, YYYY");
        this.appointmentDateDisplay = moment(this.data.appointmentDate).format("MMM Do, YYYY");
        this.paymentDateDisplay = moment(this.data.paymentDate).format("MMM Do, YYYY");
    }

    print() {
	    window.print();
	    this.close();
    }

    close() {
	    this.dialogRef.close();
    }
}
