import {Injectable} from '@angular/core';
import {URLSearchParams} from '@angular/http';
import {Observable} from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import {ApiService} from './api.service';
import {Appointment} from '../models';
import {Agent} from "../models/agent.model";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import set = Reflect.set;

let params = new URLSearchParams();

@Injectable()
export class AgentService {
	constructor(private apiService: ApiService) {
	}

	save(agent): Observable<Agent> {
		if (!agent._id) {
			return this.apiService.post("/agents/", {agent: agent})
				.map(data => data.agent);
		} else {
			return this.apiService.put("/agents/", {agent: agent})
				.map(data => data.agent);
		}
	}


	get(search): Observable<Agent> {
		params.set("search", search);
		return this.apiService.get("/agents/", params)
			.map(data => {
				return data.agents;
			});
	}

	getByName(search): Observable<Agent> {
		params.set("search", search);
		return this.apiService.get("/agents/name/", params)
			.map(data => {
				return data.agents;
			});
	}



}
