import {AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {MatDialog, MatSnackBar} from "@angular/material";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {DataSource} from "@angular/cdk/table";
// import {Observable} from "rxjs/Rx";
import {Observable} from "rxjs/Observable";
import * as moment from "moment";
import {AppointmentService} from "../../shared/services/appointment.service";
import {date} from "ng2-validation/dist/date";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Appointment} from "../../shared/models/appointment.model";
import {StringService} from "../../shared/services/string.service";
import {ConfigService} from "../../shared/services/config.service";
import {InspectorService} from "../../shared/services";
import {UtilityService} from "../../shared/services/utility.service";

@Component({
	selector: 'payroll',
	templateUrl: './inspectors-reports.component.html'
})
export class Payroll implements OnInit {
	HST = 0.13; // TODO: Use company tax and wsib % everywhere #19
	WSIB = .0296; // TODO: Use company tax and wsib % everywhere #19
	reportForm: FormGroup;
	startDateCtrl = new FormControl();
	endDateCtrl = new FormControl();
	inspectorNameCtrl = new FormControl();
	checked = false;
	inspectors : any = [];
	appointments: any;
	pricesTable: any = [];
	pricesTablePrev: any = [];
	pricesUpdates: any = [];
	subtotal = 0;
	hst = 0;
	total = 0;
	wsib = 0;
	inspectorTotal = 0;

	constructor(private appointmentService: AppointmentService,
				private snackBar: MatSnackBar,
				private stringService: StringService,
				private configService: ConfigService,
				private inspectorService: InspectorService,
				private utilityService: UtilityService,
				private fb: FormBuilder) {
		this.reportForm = this.fb.group({
			startDateCtrl: new Date(),
			endDateCtrl: new Date(),
			inspectorNameCtrl: this.inspectors[0]
		})
	}
	ngOnInit() {
        this.inspectorService.get().subscribe(val => {
            let res : any = val;
            res.forEach(x => {
                this.inspectors.push({
                    _id: x._id,
                    name: x.name
                });
                return;
            });
        });
    }

	clickPaid(event: Event, i) {
		event.stopPropagation();
	}

	resetTotals() {
		this.subtotal = 0;
		this.hst = 0;
		this.total = 0;
		this.wsib = 0;
		this.inspectorTotal = 0;
	}

	downloadReport() {
	    this.updateValues();
        let csvContent = "",
            row = "",
            appointment, i, j;
        if (!this.appointments)
            return;
        const appointmentVals = ["address", "quotedPrice", "basePrem", "sizePrem", "travelPrem", "holidayPrem", "miscPrem"],
            columnHeadings = ["Date", "Address", "Price", "Base", "Size", "Travel", "Holiday", "Misc", "Total"];
        this.inspectors.forEach(x => {
            if (x._id === this.reportForm.value.inspectorNameCtrl)
                csvContent += "Inspector: " + x.name + "\r\n";
            return;
        });
        csvContent += "\"" + moment(this.reportForm.value.startDateCtrl).format("MMM DD, YYYY") + " to " +
            moment(this.reportForm.value.endDateCtrl).format("MMM DD, YYYY") + "\"" + "\r\n";
        csvContent += columnHeadings.join(",") + "\r\n";
        for (i = 0; i < this.appointments.length; i++) {
            row = "";
            appointment = this.appointments[i];
            console.log(this.pricesTable)
            row += "\"" + moment(appointment.appointmentDate).format("MMM DD, YYYY, h:mm a") + "\"" + ",";
            for (j = 0; j < appointmentVals.length; j++) {
                if (appointment && appointment[appointmentVals[j]])
                    row += "\"" + appointment[appointmentVals[j]] + "\"" + ",";
                else
                    row += ",";
            }
            row += this.pricesTable[i][8] + ",";
            csvContent += row + "\r\n"; // add carriage return
        }
        csvContent += "\r\n";
        csvContent += "\r\n";
        csvContent += "Subtotal:," + this.subtotal.toFixed(2) + "\r\n";
        csvContent += "HST:," + this.hst.toFixed(2) +  "\r\n";
        csvContent += "Total:," + this.total.toFixed(2) + "\r\n";
        csvContent += "WSIB(2.96%) :," + this.wsib.toFixed(2) + "\r\n";
        csvContent += "Total to inspector:," + this.inspectorTotal.toFixed(2) + "\r\n";
        csvContent += "\r\n";
        csvContent += "Approved By:" + "\r\n";
        csvContent += "Date:" + "\r\n";
        csvContent += "Cheque #:" + "\r\n";
        this.utilityService.downloadCSV(csvContent, "agent-overview");
    }

	runReport(noToast) {
		this.resetTotals();
		let search = {
            startDate: new Date(this.reportForm.value.startDateCtrl.setHours(0,0,0,0)).toISOString(),
            endDate: new Date(this.reportForm.value.endDateCtrl.setHours(23,59,59,0)).toISOString(),
			inspector: this.reportForm.value.inspectorNameCtrl
		};
		this.appointmentService.getPayroll(search).subscribe(val => {
			let csvContent = "data:text/csv;charset=utf-8,",
                row = "",
                appointment, i, j;
			this.appointments = val;
			for (i = 0; i < this.appointments.length; i++) {
			    let rowTotal = 0;
				const appointment = this.appointments[i];
				rowTotal += (appointment.basePrem ? appointment.basePrem : 0)
					+ (appointment.sizePrem ? appointment.sizePrem : 0)
					+ (appointment.travelPrem ? appointment.travelPrem : 0)
					+ (appointment.holidayPrem ? appointment.holidayPrem : 0)
					+ (appointment.miscPrem ? appointment.miscPrem : 0);
				this.subtotal += rowTotal;
				this.pricesTable[i] = [appointment.quotedPrice, appointment.basePrem, appointment.sizePrem, appointment.travelPrem,
					appointment.holidayPrem, appointment.miscPrem, appointment.paid, appointment._id, rowTotal];
				this.pricesTablePrev[i] = [appointment.quotedPrice, appointment.basePrem, appointment.sizePrem, appointment.travelPrem,
					appointment.holidayPrem, appointment.miscPrem, appointment.paid, appointment._id, rowTotal];
			}
			this.hst = this.subtotal * this.HST;
			this.total = this.subtotal + this.hst;
			this.wsib = this.subtotal * this.WSIB;
			this.inspectorTotal = this.subtotal + this.hst - this.wsib;
			if (!noToast)
			    this.snackBar.open(this.stringService.reportRunSuccessfully, null, {duration: this.configService.getToastTimeout()});
		});
	}

	updateValues() {
		let updatedVals, i, j;
		this.pricesUpdates = [];
		for (i = 0; i < this.pricesTable.length; i++) {
			if (this.pricesTable[i].toString() !== this.pricesTablePrev[i].toString()) {
				updatedVals = {
					_id: this.pricesTable[i][7],
					values: {}
				};
				for (j = 0; j < this.pricesTable[i].length; j++) {
					updatedVals.values.quotedPrice = this.pricesTable[i][0];
					updatedVals.values.basePrem = this.pricesTable[i][1];
					updatedVals.values.sizePrem = this.pricesTable[i][2];
					updatedVals.values.travelPrem = this.pricesTable[i][3];
					updatedVals.values.holidayPrem = this.pricesTable[i][4];
					updatedVals.values.miscPrem = this.pricesTable[i][5];
					updatedVals.values.paid = this.pricesTable[i][6];
				}
				this.pricesUpdates.push(updatedVals);
			}
		}
		this.appointmentService.savePayroll(this.pricesUpdates).subscribe(
			val => {
				this.snackBar.open(this.stringService.payrollSaved, null, {duration: this.configService.getToastTimeout()});
				this.runReport(true);
			},
			err => {
				this.snackBar.open(this.stringService.errorSavingPayroll, null, {duration: this.configService.getToastTimeout()});
			}
		)
	}
}


