import {AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {MatDialog, MatSnackBar} from "@angular/material";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {DataSource} from "@angular/cdk/table";
// import {Observable} from "rxjs/Rx";
import {Observable} from "rxjs/Observable";
import * as moment from "moment";
import {AppointmentService} from "../../shared/services/appointment.service";
import {date} from "ng2-validation/dist/date";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Appointment} from "../../shared/models/appointment.model";
import {ConfigService} from "app/shared";
import {StringService} from "../../shared/services/string.service";
import {AgentService} from "../../shared/services/agent.service";
import {UtilityService} from "../../shared/services/utility.service";

@Component({
	selector: 'accounts-receivable',
	templateUrl: './accounts-receivable.component.html',
	styleUrls: ["./reports.styles.css"]
})
export class AccountsReceivableComponent {
	reportForm: FormGroup;
	startDateCtrl = new FormControl();
	endDateCtrl = new FormControl();
	appointments: any = [];
	subtotal = 0;
	hst = 0;
	total = 0;
	wsib = 0;
	inspectionTypesTable = [];
	overviewResultsCurrent: any = {};
	overviewResultsPrevious: any = {};
	constructor(private appointmentService: AppointmentService,
				private snackBar: MatSnackBar,
				private stringService: StringService,
				private configService: ConfigService,
				private utilityService: UtilityService,
				private fb: FormBuilder) {
		this.reportForm = this.fb.group({
			startDateCtrl: new Date(),
			endDateCtrl: new Date()
		})
	}

	runReport() {
		const search = {
            startDate: new Date(this.reportForm.value.startDateCtrl.setHours(0,0,0,0)).toISOString(),
            endDate: new Date(this.reportForm.value.endDateCtrl.setHours(23,59,59,0)).toISOString(),
		};
		this.appointmentService.getAccountsReceivable(search).subscribe(values => {
			const appVals = ["appointmentDate", "invoiceDate", "address", "total", "reminderDate1", "reminderDate2", "reminderDate3", "billTo", "billToName", "billToEmail"];
			let csvContent = "",
				row = "",
				agent, currApp, i, j, k;
			this.appointments = values;
			csvContent += appVals.join(",") + "," + "\r\n";
			for (i = 0; i < this.appointments.length; i++) {
				row = "";
				currApp = this.appointments[i];
				currApp.appointmentDate = new Date(currApp.appointmentDate).toLocaleString();
				currApp.invoiceDate = currApp.invoiceDate ? moment(currApp.invoiceDate).format("MM/DD/YYYY") : "";
				currApp.reminderDate1 = currApp.reminderDates[0] ? moment(currApp.reminderDates[0]).format("MM/DD/YYYY") : "";
				currApp.reminderDate2 = currApp.reminderDates[1] ? moment(currApp.reminderDates[1]).format("MM/DD/YYYY") : "";
				currApp.reminderDate3 = currApp.reminderDates[2] ? moment(currApp.reminderDates[2]).format("MM/DD/YYYY") : "";
                if (currApp.billTo === "Agent") {
                    currApp.billToName = currApp.agent ? currApp.agent.name : "";
                    currApp.billToEmail = currApp.agent ? currApp.agent.email : "";
                } else if (currApp.billTo === "Client") {
                    currApp.billToName = currApp.clientName;
                    currApp.billToEmail = currApp.clientEmail;
                }
				for (k = 0; k < appVals.length; k++) {
					if (currApp[appVals[k]])
						row += "\"" + currApp[appVals[k]] + "\"" + ",";
					else
						row += ",";
				}
				csvContent += row + "\r\n"; // add carriage return
			}

            this.utilityService.downloadCSV(csvContent, "accounts-receivable");
			this.snackBar.open(this.stringService.reportDownloadedSuccessfully, null, {duration: this.configService.getToastTimeout()});
		});
	}
}


