import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from "@angular/material";
import {Agent} from "../shared/models/agent.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AgentService} from "../shared/services/agent.service";
import {StringService} from "../shared/services/string.service";
import {ConfigService} from "../shared/services/config.service";
import {ErrorService} from "../shared/services/error.service";

@Component({
	selector: 'confirm-delete',
	templateUrl: './confirm.component.html',
	styleUrls: ["./delete-styles.css"]
})
export class ConfirmComponent {
	constructor(public dialogRef: MatDialogRef<ConfirmComponent>,
				private stringService: StringService,
				public snackBar: MatSnackBar,
				private configService: ConfigService,
				private errorService: ErrorService,
				@Inject(MAT_DIALOG_DATA) public data: any) {
	}
}
