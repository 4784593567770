import {CommonModule} from '@angular/common';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpModule} from '@angular/http';
import {RouterModule} from '@angular/router';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {
	MatSelectModule, MatInputModule, MatCardModule, MatAutocompleteModule, MatButtonModule,
	MatTooltipModule, MatDatepickerModule, MatTableModule, MatDialogModule
} from '@angular/material';
import {ListErrorsComponent} from './list-errors.component';
import {ShowAuthedDirective} from './show-authed.directive';
import {errorComponent} from "./error.component";
import {CdkTableModule} from "@angular/cdk/table";

@NgModule({
	imports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpModule,
		RouterModule,
		BrowserAnimationsModule,
		MatInputModule,
		MatSelectModule,
		MatCardModule,
		MatAutocompleteModule,
		MatButtonModule,
		MatDatepickerModule,
		MatTableModule,
		CdkTableModule,
		MatDialogModule
	],
	declarations: [
		ListErrorsComponent,
		ShowAuthedDirective,
		errorComponent
	],
	exports: [
		CommonModule,
		FormsModule,
		ReactiveFormsModule,
		HttpModule,
		ListErrorsComponent,
		RouterModule,
		ShowAuthedDirective,
		MatInputModule,
		MatSelectModule,
		MatCardModule,
		MatAutocompleteModule,
		MatButtonModule,
		errorComponent,
		MatTooltipModule,
		MatDatepickerModule,
		MatTableModule,
		CdkTableModule
	]
})
export class SharedModule {
}
