import {Injectable} from '@angular/core';
import {URLSearchParams} from '@angular/http';
import {Observable} from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import {ApiService} from './api.service';
import {Inspector} from '../models';

let params = new URLSearchParams();

@Injectable()
export class InspectorService {
	constructor(private apiService: ApiService) {
	}

	save(inspector): Observable<Inspector> {
        if (!inspector._id) {
            return this.apiService.post("/inspector/", {inspector: inspector})
                .map(data => data.inspector);
        } else {
            return this.apiService.put("/inspector/", {inspector: inspector})
                .map(data => data.inspector);
        }
	}


	get(): Observable<Inspector> {
		return this.apiService.get("/inspector/")
			.map(data => {
				return data.inspectors;
			});
	}



}
