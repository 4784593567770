import {AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {MatDialog, MatSnackBar} from "@angular/material";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {DataSource} from "@angular/cdk/table";
// import {Observable} from "rxjs/Rx";
import {Observable} from "rxjs/Observable";
import * as moment from "moment";
import {AppointmentService} from "../../shared/services/appointment.service";
import {date} from "ng2-validation/dist/date";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Appointment} from "../../shared/models/appointment.model";
import {ConfigService} from "app/shared";
import {StringService} from "../../shared/services/string.service";
import {AgentService} from "../../shared/services/agent.service";
import {UtilityService} from "../../shared/services/utility.service";

@Component({
	selector: 'agent-overview',
	templateUrl: './agent-overview.component.html',
	styleUrls: ["./reports.styles.css"]
})
export class AgentOverviewComponent {
	reportForm: FormGroup;
	startDateCtrl = new FormControl();
	endDateCtrl = new FormControl();
	inspectorNameCtrl = new FormControl();
	appointments: any;
	subtotal = 0;
	hst = 0;
	total = 0;
	wsib = 0;
	inspectionTypesTable = [];
	overviewResultsCurrent: any = {};
	overviewResultsPrevious: any = {};
	topAgents: any = [];

	constructor(private appointmentService: AppointmentService,
				private snackBar: MatSnackBar,
				private stringService: StringService,
				private configService: ConfigService,
				private utilityService: UtilityService,
				private fb: FormBuilder) {
		this.reportForm = this.fb.group({
			startDateCtrl: new Date(),
			endDateCtrl: new Date()
		})
	}

	runReport() {
		const search = {
            startDate: new Date(this.reportForm.value.startDateCtrl.setHours(0,0,0,0)).toISOString(),
            endDate: new Date(this.reportForm.value.endDateCtrl.setHours(23,59,59,0)).toISOString(),
		};
		this.appointmentService.getTopAgents(search).subscribe(values => {
			const agentVals = ["name", "email", "company", "officePhone", "cellPhone", "rating"];
			let csvContent = "",
				row = "",
				agent, i, j;
			this.topAgents = values;
			csvContent += agentVals.join(",") + "," + "totalInspections";
			csvContent += row + "\r\n"; // add carriage return
			for (i = 0; i < this.topAgents.length; i++) {
				row = "";
				agent = values[i].agent[0];
				for (j = 0; j < agentVals.length; j++) {
					if (agent && agent[agentVals[j]])
						row += "\"" + agent[agentVals[j]] + "\"" + ",";
					else
						row += ",";
				}
				row += this.topAgents[i].totalInspections;
				csvContent += row + "\r\n"; // add carriage return
			}
            this.utilityService.downloadCSV(csvContent, "agent-overview");
			this.snackBar.open(this.stringService.reportDownloadedSuccessfully, null, {duration: this.configService.getToastTimeout()});
		});
	}
}


