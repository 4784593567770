import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AgentsComponent} from './agents.component';
import {AuthGuard, SharedModule} from '../shared';
import {errorComponent} from "../shared/error.component";
import {InfoDialogComponent} from "../infoDialog/info-dialog.component";
import {AddAgentsComponent} from "./add-agents.component";

const agentsRouting: ModuleWithProviders = RouterModule.forChild([
	{
		path: 'agents',
		component: AgentsComponent,
		canActivate: [AuthGuard]
	}
]);

@NgModule({
	imports: [
		agentsRouting,
		SharedModule
	],
	declarations: [
		AgentsComponent
	],
	providers: []
})
export class AgentsModule {

}
