import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from "@angular/material";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {StringService} from "../../shared/services/string.service";
import {ConfigService} from "../../shared/services/config.service";
import {ErrorService} from "../../shared/services/error.service";
import {Company} from "../../shared/models/company.model";
import {CompanyService} from "../../shared/services/company.service";
import {InfoDialogComponent} from "../../infoDialog/info-dialog.component";

@Component({
	selector: 'add-company',
	templateUrl: './add-company.component.html',
	styleUrls: ["./companies-styles.css"]
})
export class AddCompanyComponent {
	values: Company = this.data ? this.data : new Company();
	rrCtrl = new FormControl();
	title;
	buttonString;
	url: string;
	MB = 1048576;
    MAX_FILE_SIZE_MB = 2;

	constructor(public dialogRef: MatDialogRef<AddCompanyComponent>,
				private fb: FormBuilder,
				private companyService: CompanyService,
				private stringService: StringService,
				public snackBar: MatSnackBar,
				private configService: ConfigService,
				private errorService: ErrorService,
				public dialog: MatDialog,
				@Inject(MAT_DIALOG_DATA) public data: any) {
		this.title = data ? "Edit Company" : "Add Company";
		this.buttonString = data ? "SAVE" : "ADD"
	}

	cancel() {
		this.dialogRef.close();
	}

    onSelectFile(event) { // called each time file input changes
        if (event.target.files && event.target.files[0]) {
            if ((event.target.files[0].size/ this.MB) > this.MAX_FILE_SIZE_MB) {
                this.dialog.open(InfoDialogComponent, {
                    data: {title: "File too large", info: "Please upload a smaller file. The maximum file size is " + this.MAX_FILE_SIZE_MB + "MB"}
                });
                return;
            }
            var reader = new FileReader();
            reader.readAsDataURL(event.target.files[0]); // read file as data url
            reader.onload = (event) => { // called once readAsDataURL is completed
                console.log(event.target.result.toString("base64"))
                this.values.logo = event.target.result;
            }
        }
    }

	submitForm() {
	    console.log(this.values)
		this.companyService.save(this.values)
			.subscribe(
				res => {
					this.snackBar.open(this.stringService.companySaved, null, {duration: this.configService.getToastTimeout()});
					this.dialogRef.close();
				},
				err => {
					this.errorService.showError(this.stringService.errorSavingCompany);
				}
			)
	}
}
