import {AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material";
import {AddInspectorComponent} from "./add-inspector.component";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {DataSource} from "@angular/cdk/table";
// import {Observable} from "rxjs/Rx";
import {Observable} from "rxjs/Observable";
import {Inspector} from "../../shared/models/inspector.model";
import {CompanyService, InspectorService} from "../../shared/services";

@Component({
	selector: 'inspectors',
	templateUrl: './inspectors.component.html',
	styleUrls: ["./inspectors-styles.css"]
})
export class InspectorsComponent implements OnInit {
	displayedColumns = ["name", "email"];
	inspectorDatabase = new InspectorDatabase();
	dataSource: InspectorDataSource | null;
    addInspectorDialog: any;
	editInspectorDialog: any;
	@Input() search: string;
	dataChange: BehaviorSubject<InspectorData[]> = new BehaviorSubject<InspectorData[]>([]);

	get data(): InspectorData[] {
		return this.dataChange.value;
	}

	emptyCompany = {
		name: undefined
	};

	constructor(public dialog: MatDialog,
		private inspectorService: InspectorService) {
	}

	ngOnInit() {
		this.dataSource = new InspectorDataSource(this);
		this.searchInspectors();
	}

	openAddInspector() {
		this.addInspectorDialog = this.dialog.open(AddInspectorComponent, {
		    disableClose: true,
            height: "auto"
		});

		this.addInspectorDialog.afterClosed().subscribe(value => {
		    this.searchInspectors();
        })
	}

    searchInspectors() {
		var arr = [];
		this.inspectorService.get().subscribe(val => {
			if (!val) return;
			for (var i = 0; i < Object.keys(val).length; i++) {
				arr.push(val[i]);
			}
			this.dataChange.next(arr);
		});
	}

	editInspector(inspector) {
		this.editInspectorDialog = this.dialog.open(AddInspectorComponent, {disableClose: true, data: inspector});

		this.editInspectorDialog.afterClosed().subscribe(value => {
			this.searchInspectors();
		})
	}
}

export interface InspectorData {
	name: string;
}

export class InspectorDatabase {
	/** Stream that emits whenever the data has been modified. */
	dataChange: BehaviorSubject<InspectorData[]> = new BehaviorSubject<InspectorData[]>([]);

	get data(): InspectorData[] {
		return this.dataChange.value;
	}

	constructor() {
	}
}

export class InspectorDataSource extends DataSource<any> {
	constructor(private _inspectorsComponent: InspectorsComponent) {
		super();
	}

	/** Connect function called by the table to retrieve one stream containing the data to render. */
	connect(): Observable<InspectorData[]> {
		return this._inspectorsComponent.dataChange;
	}

	disconnect() {
	}
}
