import {Injectable} from '@angular/core';
import {URLSearchParams} from '@angular/http';
import {Observable} from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import {ApiService} from './api.service';
import {Appointment} from '../models';

@Injectable()
export class AppointmentService {
	constructor(private apiService: ApiService) {	}

	get(search): Observable<Appointment> {
        let params = new URLSearchParams();
		params.set("search", search);
		return this.apiService.get("/appointments/", params)
			.map(data => {
				return data.appointments;
			});
	}

	getByDate(search): Observable<Appointment> {
        let params = new URLSearchParams();
		params.set("search", search);
		return this.apiService.get("/appointments/by-date", params)
			.map(data => {
				return data.appointments;
			});
	}

	getTopAgents(search): Observable<Appointment> {
        let params = new URLSearchParams();
		params.set("startDate", search.startDate);
		params.set("endDate", search.endDate);
		return this.apiService.get("/appointments/top-agents/", params)
			.map(data => {
				return data;
			});
	}

	getAppointmentsByAgent(search): Observable<Appointment> {
        let params = new URLSearchParams();
		params.set("startDate", search.startDate);
		params.set("endDate", search.endDate);
		params.set("agent", search.agent);
		return this.apiService.get("/appointments/by-agent/", params)
			.map(data => {
				return data;
			});
	}

	getAccountsReceivable(search): Observable<Appointment> {
        let params = new URLSearchParams();
		params.set("startDate", search.startDate);
		params.set("endDate", search.endDate);
		return this.apiService.get("/appointments/accounts-receivable/", params)
			.map(data => {
				return data;
			});
	}

	getPayroll(search): Observable<Appointment> {
        let params = new URLSearchParams();
		params.set("startDate", search.startDate);
		params.set("endDate", search.endDate);
		params.set("inspectorId", search.inspector);
		return this.apiService.get("/appointments/payroll/", params)
			.map(data => {
				return data.appointments;
			});
	}

	getTypeCounts(search): Observable<Appointment> {
        let params = new URLSearchParams();
		params.set("startDate", search.startDate);
		params.set("endDate", search.endDate);
		params.set("inspectorId", search.inspector);
		return this.apiService.get("/appointments/inspection-types/", params)
			.map(data => {
				return data;
			});
	}

	getInspectorsOverview(search): Observable<Appointment> {
        let params = new URLSearchParams();
		params.set("startDate", search.startDate);
		params.set("endDate", search.endDate);
		params.set("inspectorId", search.inspector);
		return this.apiService.get("/appointments/inspectors-overview/", params)
			.map(data => {
				return data;
			});
	}

	getCompanyOverview(search): Observable<Appointment> {
        let params = new URLSearchParams();
		params.set("startDate", search.startDate);
		params.set("endDate", search.endDate);
		return this.apiService.get("/appointments/company-overview/", params)
			.map(data => {
				return data;
			});
	}

	delete(appointment): Observable<Appointment> {
		return this.apiService.delete("/appointment/" + appointment._id)
			.map(data => data.appointment);
	}

	save(appointment): Observable<Appointment> {
		// Create appointment
		if (!appointment._id) {
			return this.apiService.post("/appointment/", {appointment: appointment})
				.map(data => data.appointment);
		} else {
			return this.apiService.put("/appointment/", {appointment: appointment})
				.map(data => data.appointment);
		}
	}

	// Update payroll values
	savePayroll(values): Observable<any> {
		return this.apiService.put("/appointment/payroll/", {values: values})
			.map(data => console.log(data));
	}

	email(appointment): Observable<Appointment> {
		// Email appointment
		return this.apiService.put("/appointment/email/", {appointment: appointment})
			.map(data => data.appointment)
	}
}
