export class Agent {
	_id: string;
	name: string;
	email: string;
	company: string;
	officePhone: string;
	cellPhone: string;
	internalNotes: string;
	externalNotes: string;
	rating: string;
}


