export class Company {
	_id: string;
	name: string;
	street: string;
	city: string;
	province: string;
	postal: string;
	phone: string;
	fax: string;
	website: string;
	email: string;
	tax: number;
	taxNumber: string;
	wsib: number;
	defaultAgentDiscount: number;
	message1: string;
	message2: string;
	logo: string;
}


