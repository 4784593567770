import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Rx';

import {UserService} from '../shared';

@Injectable()
export class HomeAuthResolver implements Resolve<boolean> {
	constructor(private router: Router,
				private userService: UserService) {
	}

	resolve(route: ActivatedRouteSnapshot,
			state: RouterStateSnapshot): Observable<boolean> {

		return this.userService.isAuthenticated.take(1);

	}
}
