import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from "@angular/material";
import {Agent} from "../shared/models/agent.model";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {AgentService} from "../shared/services/agent.service";
import {StringService} from "../shared/services/string.service";
import {ConfigService} from "../shared/services/config.service";
import {ErrorService} from "../shared/services/error.service";

@Component({
	selector: 'add-agents',
	templateUrl: './add-agents.component.html',
	styleUrls: ["./agents-styles.css"]
})
export class AddAgentsComponent {
	agent: Agent = new Agent();
	agentForm: FormGroup;
	nameCtrl = new FormControl();
	rrCtrl = new FormControl();
	title;
	buttonString;

	ratings = ["1", "2", "3", "4", "5"];

	constructor(public dialogRef: MatDialogRef<AddAgentsComponent>,
				private fb: FormBuilder,
				private agentService: AgentService,
				private stringService: StringService,
				public snackBar: MatSnackBar,
				private configService: ConfigService,
				private errorService: ErrorService,
				@Inject(MAT_DIALOG_DATA) public data: any) {
		this.agentForm = this.fb.group({
			nameCtrl: [data && data.name ? data.name : undefined, Validators.required],
			emailCtrl: [data && data.email ? data.email : undefined, [Validators.email]],
			companyCtrl: data && data.company ? data.company : undefined,
			officeCtrl: data && data.officePhone ? data.officePhone : undefined,
			cellCtrl: data && data.cellPhone ? data.cellPhone : undefined,
			internalNotesCtrl: data && data.internalNotes ? data.internalNotes : undefined,
			externalNotesCtrl: data && data.externalNotes ? data.externalNotes : undefined,
			rrCtrl: data && data.rating ? data.rating : undefined
		})
		this.title = data ? "Edit Realtor" : "Add Realtor";
		this.buttonString = data ? "SAVE" : "ADD"
	}

	cancel() {
		this.dialogRef.close();
		//TODO: Implement confirmation dialog
	}

	submitForm() {
		this.updateAgent(this.agentForm.value);
		this.agentService.save(this.agent)
			.subscribe(
				res => {
					this.snackBar.open(this.stringService.agentSaved, null, {duration: this.configService.getToastTimeout()});
					this.dialogRef.close();
				},
				err => {
					this.errorService.showError(this.stringService.errorSavingAgent);
				}
			)
	}

	updateAgent(values: any) {
		if (this.data && this.data._id) this.agent._id = this.data._id;
		this.agent.name = values.nameCtrl;
		this.agent.email = values.emailCtrl;
		this.agent.company = values.companyCtrl;
		this.agent.officePhone = values.officeCtrl;
		this.agent.cellPhone = values.cellCtrl;
		this.agent.internalNotes = values.internalNotesCtrl;
		this.agent.externalNotes = values.externalNotesCtrl;
		this.agent.rating = values.rrCtrl;
	}

}
