import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {BookAppointmentComponent} from './book-appointment.component';
import {AuthGuard, SharedModule} from '../shared';
import {errorComponent} from "../shared/error.component";
import {InfoDialogComponent} from "../infoDialog/info-dialog.component";
import {ConfirmComponent} from "../confirm/confirm.component";
import {MatCheckboxModule, MatMenuModule} from "@angular/material";
import {AddAgentsComponent} from "../agents/add-agents.component";
import {InvoiceComponent} from "../invoice/invoice.component";

const bookAppointmentRouting: ModuleWithProviders = RouterModule.forChild([
	{
		path: 'book',
		component: BookAppointmentComponent,
		canActivate: [AuthGuard]
	}
]);

@NgModule({
	imports: [
		bookAppointmentRouting,
		SharedModule,
		MatCheckboxModule,
        MatMenuModule
	],
	declarations: [
		BookAppointmentComponent
	],
	providers: [],
	entryComponents: [
		InfoDialogComponent,
		ConfirmComponent,
        AddAgentsComponent,
        InvoiceComponent
	]
})
export class BookAppointmentModule {

}
