import {AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material";
import {AddCompanyComponent} from "./add-company.component";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {DataSource} from "@angular/cdk/table";
// import {Observable} from "rxjs/Rx";
import {Observable} from "rxjs/Observable";
import {Company} from "../../shared/models/company.model";
import {CompanyService} from "../../shared/services";

@Component({
	selector: 'companies',
	templateUrl: './companies.component.html',
	styleUrls: ["./companies-styles.css"]
})
export class CompaniesComponent implements OnInit {
	displayedColumns = ["name"];
	companyDatabase = new CompanyDatabase();
	dataSource: CompanyDataSource | null;
    addCompanyDialog: any;
	editCompanyDialog: any;
	@Input() search: string;
	dataChange: BehaviorSubject<CompanyData[]> = new BehaviorSubject<CompanyData[]>([]);

	get data(): CompanyData[] {
		return this.dataChange.value;
	}

	constructor(public dialog: MatDialog,
		private companyService: CompanyService) {
	}

	ngOnInit() {
		this.dataSource = new CompanyDataSource(this);
		this.searchCompanies();
	}

	openAddCompany() {
		this.addCompanyDialog = this.dialog.open(AddCompanyComponent, {
		    disableClose: true,
            maxWidth: "30vw"
		});

		this.addCompanyDialog.afterClosed().subscribe(value => {
		    this.searchCompanies();
        });
	}

    searchCompanies() {
		var arr = [];
		this.companyService.getLogos().subscribe(val => {
			if (!val) return;
			for (var i = 0; i < Object.keys(val).length; i++) {
				arr.push(val[i]);
			}
			this.dataChange.next(arr);
		});
	}

	editCompany(company) {
		this.editCompanyDialog = this.dialog.open(AddCompanyComponent, {
		    disableClose: true,
            data: company,
            maxWidth: "30vw"
		});

		this.editCompanyDialog.afterClosed().subscribe(value => {
			this.searchCompanies();
		});
	}
}

export interface CompanyData {
	name: string;
}

export class CompanyDatabase {
	/** Stream that emits whenever the data has been modified. */
	dataChange: BehaviorSubject<CompanyData[]> = new BehaviorSubject<CompanyData[]>([]);

	get data(): CompanyData[] {
		return this.dataChange.value;
	}

	constructor() {
	}
}

export class CompanyDataSource extends DataSource<any> {
	constructor(private _companiesComponent: CompaniesComponent) {
		super();
	}

	/** Connect function called by the table to retrieve one stream containing the data to render. */
	connect(): Observable<CompanyData[]> {
		return this._companiesComponent.dataChange;
	}

	disconnect() {
	}
}
