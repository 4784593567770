import {Component, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material";

@Component({
	selector: 'info-dialog',
	templateUrl: './info-dialog.component.html'
})
export class InfoDialogComponent {
	constructor(public dialogRef: MatDialogRef<InfoDialogComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
	}

	close() {
		this.dialogRef.close();
	}
}
