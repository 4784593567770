import {ApplicationRef, ChangeDetectorRef, Component, Inject, OnInit, Optional} from '@angular/core';
import {FormBuilder, FormGroup, FormControl, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Appointment} from '../shared';
import 'rxjs/add/operator/startWith';
import 'rxjs/add/operator/map';
import {any} from "codelyzer/util/function";
import {ConfigService} from "../shared/services/config.service";
import {AddressService} from "../shared/services/address.service";
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatIconRegistry, MatSnackBar} from "@angular/material";
import {DomSanitizer} from "@angular/platform-browser";
import {AppointmentService} from "../shared/services/appointment.service";
import * as moment from "moment";
import {CustomValidators} from "ng2-validation";
import {ErrorService} from "../shared/services/error.service";
import {StringService} from "../shared/services/string.service";
import {AgentService} from "../shared/services/agent.service";
import {Agent} from "../shared/models/agent.model";
import {AddAgentsComponent} from "../agents/add-agents.component";
import {ConfirmComponent} from "app/confirm/confirm.component";
import {isNullOrUndefined} from "util";
import {UserService} from "../shared/services/user.service";
import {CompanyService, InspectorService} from "../shared/services";
import {Observable} from "rxjs/Observable";
import {InvoiceComponent} from "app/invoice/invoice.component";

declare var google: any;

@Component({
	selector: 'book-appointment-page',
	templateUrl: './book-appointment.component.html',
	styleUrls: ["./book-appointment.css"]
})
export class BookAppointmentComponent implements OnInit {
	appointment: Appointment = new Appointment();
	bookAppointmentForm: FormGroup;
	dateCtrl = new FormControl();
	timeCtrl = new FormControl();
	inspectorNameCtrl = new FormControl();
	inspectionTypeCtrl = new FormControl();
	referralSourceCtrl = new FormControl();
	clientFullNameCtrl = new FormControl();
	clientPhoneNumCtrl = new FormControl();
	clientEmailCtrl = new FormControl();
	billToCtrl = new FormControl();
	billToNameCtrl = new FormControl();
	billToEmailCtrl = new FormControl();
	quotedPriceCtrl = new FormControl();
	adjustmentCtrl = new FormControl();
    subtotalCtrl = new FormControl();
	quotedHSTCtrl = new FormControl();
	quotedTotalCtrl = new FormControl();
	sqFootageCtrl = new FormControl();
	buildingTypeCtrl = new FormControl();
	listPriceCtrl = new FormControl();
	addressCtrl = new FormControl();
	lockboxCtrl = new FormControl();
	notesCtrl = new FormControl();
	paymentDetailsCtrl = new FormControl();
	paidCtrl: boolean = false;
    disclaimerCtrl: boolean = false;
    invoiceDateCtrl = new FormControl();
    reminderDate1Ctrl = new FormControl();
    reminderDate2Ctrl = new FormControl();
    reminderDate3Ctrl = new FormControl();
    paymentDateCtrl = new FormControl();
    paymentForCtrl = new FormControl();
	requestedCtrl : boolean = false;
	agentSearchCtrl = new FormControl();
	agentEmailCtrl = new FormControl();
	agentCompanyCtrl = new FormControl();
	agentOfficeCtrl = new FormControl();
	agentCellCtrl = new FormControl();
	basePremCtrl = new FormControl();
	sizePremCtrl = new FormControl();
	travelPremCtrl = new FormControl();
	holidayPremCtrl = new FormControl();
	miscPremCtrl = new FormControl();
	placeSet: boolean = false;
	quotedPriceVal : any = 0;
	adjustmentVal : any = 0;
    subtotalVal : any = 0;
	hstVal : any = 0;
	totalVal : any = 0;
	agent = Agent;
	autocomplete;
	selectedAgentId = undefined;
	addAgentsDialog: any;
	confirmDialog: any;
	invoiceDialog: any;
	errors: Object = {};
	hasData: boolean = false;
	prevApps: any = [];
	companies : any = [];
	filteredCompanies: any;
    companyNameCtrl : any;
    companyRes;
    inspectorRes;
    selectedCompany = {_id: null, name: null, defaultAgentDiscount: 0, tax: 0};
    selectedInspector = {_id: null, name: null};
    discountPercentage = 0;
    taxPercentage = 0;

	inspectors = [];
	filteredInspectors: any;

	inspectionTypes = [
		"Pre-List", "Pre-Purchase", "Pre-Offer", "U.S Inspect", "1 Year", "PDI", "30 Day", "Legal", "HO", "Walk-Thru",
		"Post-Purchase", "BCS", "Revisit", "Renovation Coaching", "Problem Solving"
	];
	filteredTypes: any;

	refSources = [
		"Previous client", "Internet", "Pre-list binder", "Builder", "Friend", "Agent", "Lawyer", "Insurance",
		"Other"
	];
	filteredRefSource: any;
	sqFootage = [
		"1000", "1500", "2000", "2500", "3000", "3500", "4000", "4500", "5000", "5500", "6000", "7000", "8000", "9000", "10000"
	];
	filteredSqFootage: any;

	buildingType = [
		"Single", "Duplex", "3plex", "4plex", "6-8plex", "Store & Apt", "Condo", "Apt Building", "Condo townhouse", "Commercial"
	];
	filteredBuildingType: any = [];

	agentResults: any = [];

	listPrice = [ "100,000", "200,000", "300,000", "400,000", "500,000", "600,000", "700,000", "800,000", "900,000", "1,000,000"];
    billToOptions = ["Agent", "Client", "Other"];

    paymentForOptions = ["Inspection", "Inspection and report", "Follow-up inspection", "Follow-up inspection and report",
        "Consultation", "Consultation and report", "Legal Inspection and report", "Retainer for Legal Inspection"];

	filteredListPrice: any = [];
    snackBarConfig = {
        duration: this.configService.getToastTimeout(),
        panelClass: "snackbar-hide"
    };

	constructor(@Optional() public dialogRef: MatDialogRef<AddAgentsComponent>,
				@Optional() public confirmDialogRef: MatDialogRef<ConfirmComponent>,
				@Optional() public invoiceDialogRef: MatDialogRef<ConfirmComponent>,
				private appointmentService: AppointmentService,
				private agentService: AgentService,
				private configService: ConfigService,
				private userService: UserService,
				private companyService: CompanyService,
				private inspectorService: InspectorService,
				private fb: FormBuilder,
				public snackBar: MatSnackBar,
				private errorService: ErrorService,
				private stringService: StringService,
				public dialog: MatDialog,
				@Optional() @Inject(MAT_DIALOG_DATA) public data: any,
				private cdr: ChangeDetectorRef) {
		this.bookAppointmentForm = this.fb.group({
			companyNameCtrl: data && data.company ? data.company.name : this.companies[0],
			inspectorNameCtrl: data && data.inspector ? data.inspector.name : this.inspectors[0],
			inspectionTypeCtrl: data && data.inspectionType ? data.inspectionType : undefined,
			referralSourceCtrl: data && data.referralSource ? data.referralSource : undefined,
			clientFullNameCtrl: data && data.clientName ? data.clientName : undefined,
			clientPhoneNumCtrl: data && data.clientPhone ? data.clientPhone : undefined,
			clientEmailCtrl: data && data.clientEmail ? data.clientEmail : undefined,
            billToCtrl: [data && data.billTo ? [data.billTo] : undefined],
            billToNameCtrl: [{value: data && data.billToName ? data.billToName : undefined, disabled: true}],
            billToEmailCtrl: [{value: data && data.billToEmail ? data.billToEmail : undefined, disabled: true}],
			quotedPriceCtrl: [0, [Validators.pattern("-?\\d+(\\.\\d{1,2})?")]],
            adjustmentCtrl: [0, [Validators.pattern("-?\\d+(\\.\\d{1,2})?")]],
            subtotalCtrl: [{value: 0, disabled: true}],
			quotedHSTCtrl: [{value: 0, disabled: true}],
			quotedTotalCtrl: [{value: 0, disabled: true}],
			sqFootageCtrl: data && data.sqFootage ? [data.sqFootage] : undefined,
			buildingTypeCtrl: data && data.buildingType ? data.buildingType : undefined,
			listPriceCtrl: data && data.listPrice ? data.listPrice : undefined,
			notesCtrl: data && data.notes ? data.notes : undefined,
			addressCtrl: data && data.address ? data.address : undefined,
			lockboxCtrl: data && data.lockbox ? data.lockbox : undefined,
			agentSearchCtrl: data && data.agent && data.agent.name ? data.agent.name : undefined,
			agentEmailCtrl: [{
				value: data && data.agent && data.agent.email ? data.agent.email : undefined,
				disabled: true
			}],
			agentCompanyCtrl: [{
				value: data && data.agent && data.agent.company ? data.agent.company : undefined,
				disabled: true
			}],
			agentOfficeCtrl: [{
				value: data && data.agent && data.agent.officePhone ? data.agent.officePhone : undefined,
				disabled: true
			}],
			agentCellCtrl: [{
				value: data && data.agent && data.agent.cellPhone ? data.agent.cellPhone : undefined,
				disabled: true
			}],
			dateCtrl: [data && data.appointmentDate ? data.appointmentDate : new Date(), Validators.required],
			timeCtrl: [data && data.appointmentDate ? moment(data.appointmentDate).format("HH:mm") : undefined, Validators.required],
			basePremCtrl: data && data.basePrem ? data.basePrem : 0,
			sizePremCtrl: data && data.sizePrem ? data.sizePrem : 0,
			travelPremCtrl: data && data.travelPrem ? data.travelPrem : 0,
			holidayPremCtrl: data && data.holidayPrem ? data.holidayPrem : 0,
			miscPremCtrl: data && data.miscPrem ? data.miscPrem : 0,
			paymentDetailsCtrl: data && data.paymentDetails ? data.paymentDetails : undefined,
            invoiceDateCtrl: data && data.invoiceDate ? data.invoiceDate : undefined,
            reminderDate1Ctrl: data && data.reminderDates[0] ? data.reminderDates[0] : undefined,
            reminderDate2Ctrl: data && data.reminderDates[1] ? data.reminderDates[1] : undefined,
            reminderDate3Ctrl: data && data.reminderDates[2] ? data.reminderDates[2] : undefined,
            paymentDateCtrl: data && data.paymentDate ? data.paymentDate : undefined,
            paymentForCtrl: data && data.paymentFor ? data.paymentFor : "Inspection and report"
		});
		if (this.data) this.hasData = true;

		this.filteredTypes = this.bookAppointmentForm.get("inspectionTypeCtrl").valueChanges
			.startWith(undefined)
			.map(name => this.filterArray(name, this.inspectionTypes));

		this.filteredCompanies = this.bookAppointmentForm.get("companyNameCtrl").valueChanges
			.startWith(undefined)
			.map(name => this.filterArray(name, this.companies));

		this.filteredInspectors = this.bookAppointmentForm.get("inspectorNameCtrl").valueChanges
			.startWith(undefined)
			.map(name => this.filterArray(name, this.inspectors));

		this.filteredRefSource = this.bookAppointmentForm.get("referralSourceCtrl").valueChanges
			.startWith(undefined)
			.map(name => this.filterArray(name, this.refSources));

		this.filteredSqFootage = this.bookAppointmentForm.get("sqFootageCtrl").valueChanges
			.startWith(undefined)
			.map(name => this.filterArray(name, this.sqFootage));

		this.filteredListPrice = this.bookAppointmentForm.get("listPriceCtrl").valueChanges
			.startWith(undefined)
			.map(name => this.filterArray(name, this.listPrice));

		this.filteredBuildingType = this.bookAppointmentForm.get("buildingTypeCtrl").valueChanges
			.startWith(undefined)
			.map(name => this.filterArray(name, this.buildingType));

		this.bookAppointmentForm.get("quotedPriceCtrl").valueChanges.subscribe(value => {
            if (this.bookAppointmentForm.get("quotedPriceCtrl").errors || this.bookAppointmentForm.get("adjustmentCtrl").errors) {
                this.bookAppointmentForm.get("quotedHSTCtrl").setValue("");
                this.bookAppointmentForm.get("quotedTotalCtrl").setValue("");
            } else {
                if (this.data || this.bookAppointmentForm.get("adjustmentCtrl").touched) {
                    this.calculateTaxTotal();
                    return;
                }
                if (this.bookAppointmentForm.get("billToCtrl").value === "Agent") {
                    let adjustment = value * this.discountPercentage * -1;
                    this.adjustmentVal = Number.isNaN(adjustment) ? 0 : adjustment.toFixed(2);
                }
                this.calculateTaxTotal();
             }
        });

        this.bookAppointmentForm.get("adjustmentCtrl").valueChanges.subscribe(value => {
            this.calculateTaxTotal();
        });

		this.bookAppointmentForm.get("companyNameCtrl").valueChanges.subscribe(value => {
		    this.companyRes.forEach(x => {
		       if (x.name === value) {
                   this.selectedCompany = {
                       _id: x._id,
                       name: x.name,
                       defaultAgentDiscount: x.defaultAgentDiscount,
                       tax: x.tax
                   };
               }
            });
		});

		this.bookAppointmentForm.get("inspectorNameCtrl").valueChanges.subscribe(value => {
		    this.inspectorRes.forEach(x => {
		       if (x.name === value) {
                   this.selectedInspector = {
                       _id: x._id,
                       name: x.name
                   };
               }
            });
		});

		this.bookAppointmentForm.get("agentSearchCtrl").valueChanges.subscribe(value => {
			if (value && this.selectedAgentId)
				this.bookAppointmentForm.get("agentSearchCtrl").setValidators(Validators.required);
			else if (value !== undefined)
				this.bookAppointmentForm.get("agentSearchCtrl").setErrors({"No realtor selected": "You must select a realtor."});

			this.agentResults = [];
			this.agentService.getByName(value).subscribe(agents => {
				if (!agents || Object.keys(agents).length === 0) return;
				const numResults = Math.min(Object.keys(agents).length, 5);
				for (let i = 0; i < numResults; i++) {
					this.agentResults.push(agents[i]);
				}
			})
		});

        this.bookAppointmentForm.get("clientFullNameCtrl").valueChanges.subscribe(value => {
            this.billToChanged();
        });

        this.bookAppointmentForm.get("clientEmailCtrl").valueChanges.subscribe(value => {
            this.billToChanged();
        });

		this.bookAppointmentForm.get("addressCtrl").valueChanges.subscribe(value => {
			this.placeSet = false;
            this.appointmentService.get(value).subscribe(result => {
                if (result) {
                    this.prevApps = result;
                    this.prevApps = this.prevApps.length;
                }
            });
		})

	}

    calculateTaxTotal() {
        let price = parseFloat(this.bookAppointmentForm.get("quotedPriceCtrl").value);
        let adjustment = parseFloat(this.bookAppointmentForm.get("adjustmentCtrl").value);
        if (!price)
            price = 0;
        if (!adjustment)
            adjustment = 0;
        if (this.bookAppointmentForm.get("quotedPriceCtrl").errors || this.bookAppointmentForm.get("adjustmentCtrl").errors) {
            this.bookAppointmentForm.get("quotedHSTCtrl").setValue("");
            this.bookAppointmentForm.get("quotedTotalCtrl").setValue("");
        } else {
            this.hstVal = ((price + adjustment) * this.taxPercentage).toFixed(2);
            this.subtotalVal = (price + adjustment).toFixed(2);
            this.totalVal = (price + adjustment + parseFloat(this.hstVal)).toFixed(2);
        }
    }

	selectAllContent($event) {
		$event.target.select();
	}

	filterArray(val: string, array: string[]) {
		return val ? array.filter(s => s.toLowerCase().includes(val.toLowerCase()))
			: array;
	}

	cancel() {
		this.dialogRef.close();
		// TODO: Implement confirmation dialog
	}

	agentSelected(selected) {
		this.bookAppointmentForm.get("agentSearchCtrl").disable();
		this.bookAppointmentForm.get("agentEmailCtrl").disable();
		this.bookAppointmentForm.get("agentEmailCtrl").setValue(selected.email);
		this.bookAppointmentForm.get("agentCompanyCtrl").disable();
		this.bookAppointmentForm.get("agentCompanyCtrl").setValue(selected.company);
		this.bookAppointmentForm.get("agentOfficeCtrl").disable();
		this.bookAppointmentForm.get("agentOfficeCtrl").setValue(selected.officePhone);
		this.bookAppointmentForm.get("agentCellCtrl").disable();
		this.bookAppointmentForm.get("agentCellCtrl").setValue(selected.cellPhone);
		this.selectedAgentId = selected._id;
		this.billToChanged();
	}

	openAddAgents() {
		this.addAgentsDialog = this.dialog.open(AddAgentsComponent, {disableClose: true, maxWidth: "30vw"});
	}

	agentReset() {
		this.bookAppointmentForm.get("agentSearchCtrl").enable();
		this.bookAppointmentForm.get("agentSearchCtrl").setValue(undefined);
		this.bookAppointmentForm.get("agentEmailCtrl").setValue(undefined);
		this.bookAppointmentForm.get("agentCompanyCtrl").setValue(undefined);
		this.bookAppointmentForm.get("agentOfficeCtrl").setValue(undefined);
		this.bookAppointmentForm.get("agentCellCtrl").setValue(undefined);
		this.selectedAgentId = null;
		this.appointment.agent = null;
		this.bookAppointmentForm.get("agentSearchCtrl").clearValidators();
		this.bookAppointmentForm.get("agentSearchCtrl").setErrors([]);
		this.bookAppointmentForm.get("agentSearchCtrl").updateValueAndValidity();
		this.billToChanged();
	}

	billToChanged() {
        var billTo = this.bookAppointmentForm.get("billToCtrl").value;
        if (!this.data) {
            this.bookAppointmentForm.get("billToNameCtrl").setValue("");
            this.bookAppointmentForm.get("billToEmailCtrl").setValue("");
        }
	    if (billTo === "Other") {
            this.bookAppointmentForm.get("billToNameCtrl").enable();
            this.bookAppointmentForm.get("billToEmailCtrl").enable();
        } else {
            this.bookAppointmentForm.get("billToNameCtrl").disable();
            this.bookAppointmentForm.get("billToEmailCtrl").disable();
            if (billTo === "Agent") {
                var rName = this.bookAppointmentForm.get("agentSearchCtrl").value;
                var rEmail = this.bookAppointmentForm.get("agentEmailCtrl").value;
                this.bookAppointmentForm.get("billToNameCtrl").setValue(rName);
                this.bookAppointmentForm.get("billToEmailCtrl").setValue(rEmail);
            } else if (billTo === "Client") {
                var cName = this.bookAppointmentForm.get("clientFullNameCtrl").value;
                var cEmail = this.bookAppointmentForm.get("clientEmailCtrl").value;
                this.bookAppointmentForm.get("billToNameCtrl").setValue(cName);
                this.bookAppointmentForm.get("billToEmailCtrl").setValue(cEmail);
            }
        }
    }

	emailApp() {
		this.updateAppointment(this.bookAppointmentForm.value);
		this.confirmDialog = this.dialog.open(ConfirmComponent, {
			disableClose: false,
			data: {
				title: "EMAIL",
				message: "Are you sure you want to email this appointment?",
				secondaryButton: "CANCEL",
				primaryButton: "EMAIL"
			}
		});
		this.confirmDialog.afterClosed().subscribe(result => {
			if (result) {
				this.appointmentService.email(this.appointment)
					.subscribe(
						app => {
							this.snackBar.open(this.stringService.appointmentEmailed, null, this.snackBarConfig);
							if (this.confirmDialog) {
								this.confirmDialogRef.close();
							}
						},
						err => {
							this.errorService.showError(this.stringService.errorEmailingAppointment);
						}
					)
			}
		});
	}

	generateInvoice(paid) {
	    if (!this.bookAppointmentForm.get("invoiceDateCtrl").value) this.bookAppointmentForm.get("invoiceDateCtrl").setValue(new Date());
	    if (paid && !this.bookAppointmentForm.get("paymentDateCtrl").value) this.bookAppointmentForm.get("paymentDateCtrl").setValue(new Date());
        this.updateAppointment(this.bookAppointmentForm.value);
        this.submitForm(false, true);
        this.invoiceDialog = this.dialog.open(InvoiceComponent, {
            disableClose: false,
            data: this.appointment,
            maxWidth: "100vw"
        });
    }

	deleteApp() {
		this.confirmDialog = this.dialog.open(ConfirmComponent, {
			disableClose: false,
			data: {
				title: "DELETE",
				message: "Are you sure you want to delete this appointment?",
				secondaryButton: "CANCEL",
				primaryButton: "DELETE",
				danger: true
			}
		});
		this.updateAppointment(this.bookAppointmentForm.value)
		this.confirmDialog.afterClosed().subscribe(result => {
			if (result) {
				this.appointmentService.delete(this.appointment)
					.subscribe(
						app => {
							this.snackBar.open(this.stringService.appointmentDeleted, null, this.snackBarConfig);
							if (this.confirmDialog) {
								this.confirmDialogRef.close();
							}
						},
						err => {
							this.errorService.showError(this.stringService.errorDeletingAppointment);
						}
					)
			}
		});
	}

	geolocate(auto) {
		if (navigator.geolocation) {
			navigator.geolocation.getCurrentPosition(function (position) {
				var geolocation = {
					lat: position.coords.latitude,
					lng: position.coords.longitude
				};
				var circle = new google.maps.Circle({
					center: geolocation,
					radius: 250000
				});
				auto.setBounds(circle.getBounds());
			});
		}
	}

	openInMaps() {
		var place = this.autocomplete.getPlace();
		if (place && place.url) {
            window.open(place.url);
		}
	}

	ngOnInit() {
		this.autocomplete = new google.maps.places.Autocomplete(document.getElementById("autocomplete"));
		this.geolocate(this.autocomplete);
		if (this.data) this.paidCtrl = this.data.paid;
		if (this.data) this.disclaimerCtrl = this.data.disclaimer;
		if (this.data) this.requestedCtrl = this.data.inspectorRequested;
		if (this.data && this.data.addressURL) this.appointment.addressURL = this.data.addressURL;
		this.autocomplete.addListener("place_changed", () => {
			this.placeSet = true;
			this.bookAppointmentForm.get("addressCtrl").setValue(this.autocomplete.getPlace().formatted_address);
			this.appointment.addressURL = this.autocomplete.getPlace().url;
			this.appointmentService.get(this.autocomplete.getPlace() ? this.autocomplete.getPlace().formatted_address : null).subscribe(result => {
				if (result) {
					this.prevApps = result;
					this.prevApps = this.prevApps.length;
				}
			});
		});
        this.companyService.get()
            .subscribe(val => {
                this.companyRes = val;
                this.companyRes.forEach(x => {
                    this.companies.push(x.name);
                });
                if (!this.data) {
                    this.bookAppointmentForm.get("companyNameCtrl").setValue(this.companyRes[0].name);
                    this.selectedCompany = {
                        _id: this.companyRes[0]._id,
                        name: this.companyRes[0].name,
                        defaultAgentDiscount: this.companyRes[0].defaultAgentDiscount * .01,
                        tax: this.companyRes[0].tax * .01
                    };
                } else if (this.data.company) {
                    this.bookAppointmentForm.get("companyNameCtrl").setValue(this.data.company.name);
                    this.selectedCompany = {
                        _id: this.data.company._id,
                        name: this.data.company.name,
                        defaultAgentDiscount: this.data.company.defaultAgentDiscount * .01,
                        tax: this.data.company.tax * .01
                    };
                }
                this.taxPercentage = this.selectedCompany.tax ? this.selectedCompany.tax : 0;
                this.discountPercentage = this.selectedCompany.defaultAgentDiscount ? this.selectedCompany.defaultAgentDiscount : 0;
                if (this.data && this.data.quotedPrice) {
                    this.quotedPriceVal = this.data.quotedPrice;
                    this.adjustmentVal = this.data.adjustment ? this.data.adjustment : 0;
                    this.subtotalVal = this.quotedPriceVal + this.adjustmentVal;
                    this.hstVal = this.data.tax ? this.data.tax : 0;
                    this.totalVal = this.data.total ? this.data.total : 0;
                }
            });
        this.inspectorService.get()
            .subscribe(val => {
                this.inspectorRes = val;
                this.inspectorRes.forEach(x => {
                    this.inspectors.push(x.name);
                });
                if (!this.data) {
                    this.bookAppointmentForm.get("inspectorNameCtrl").setValue(this.inspectorRes[0].name);
                    this.selectedInspector = {
                        _id: this.inspectorRes[0]._id,
                        name: this.inspectorRes[0].name
                    };
                } else if (this.data.inspector) {
                    this.bookAppointmentForm.get("inspectorNameCtrl").setValue(this.data.inspector.name);
                    this.selectedInspector = {
                        _id: this.data.inspector._id,
                        name: this.data.inspector.name
                    };
                }
            });
        if (this.data) this.bookAppointmentForm.get("billToCtrl").setValue(this.data.billTo);
        this.billToChanged();
        if (this.data && this.data.agent) this.selectedAgentId = this.data.agent;
    }

	resetForm() {
		this.bookAppointmentForm.reset({
			dateCtrl: moment(),
            timeCtrl: moment().format("HH:mm"),
            quotedPriceCtrl: 0,
            adjustmentCtrl: 0,
            subtotalCtrl: 0,
            quotedHSTCtrl: 0,
            quotedTotalCtrl: 0
		});
        this.bookAppointmentForm.get("companyNameCtrl").setValue(this.companyRes[0].name);
        this.selectedCompany = {
            _id: this.companyRes[0]._id,
            name: this.companyRes[0].name,
            defaultAgentDiscount: this.companyRes[0].defaultAgentDiscount,
            tax: this.companyRes[0].tax
        };
        this.bookAppointmentForm.get("inspectorNameCtrl").setValue(this.inspectorRes[0].name);
        this.selectedInspector = {
            _id: this.inspectorRes[0]._id,
            name: this.inspectorRes[0].name
        };
		this.autocomplete.formatted_address = "";
		this.autocomplete.place = "";
		this.appointment = new Appointment();
		this.prevApps = 0;
		this.agentReset();
		if (this.dialogRef) {
			this.dialogRef.close();
		}
	}


	submitForm(email, keepOpen?) {
		this.updateAppointment(this.bookAppointmentForm.value);
		this.appointmentService.save(this.appointment)
			.subscribe(
				app => {
				    if (!email) {
                        if (this.data)
                            this.snackBar.open(this.stringService.appointmentUpdated, null, this.snackBarConfig);
                        else
                            this.snackBar.open(this.stringService.appointmentCreated, null, this.snackBarConfig);
                        if (!keepOpen) this.resetForm();
                    } else {
				        this.emailApp();
                    }
				},
				err => {
					this.errorService.showError(err);
				}
			)
	}

	updateAppointment(values: any) {
		let appDate = moment(values.dateCtrl);
		let time = moment(values.timeCtrl, "hh:mm:aa");
		let place = this.autocomplete.getPlace();
		appDate.hour(time.hour()).minute(time.minute());
		if (this.data && this.data._id) this.appointment._id = this.data._id;
		if (values.companyNameCtrl) this.appointment.company = this.selectedCompany._id;
		if (values.inspectorNameCtrl) this.appointment.inspector = this.selectedInspector._id;
		if (appDate) this.appointment.appointmentDate = new Date(appDate.toISOString());
		if (values.inspectionTypeCtrl) this.appointment.inspectionType = values.inspectionTypeCtrl;
		if (this.placeSet) {
            this.appointment.address = place.formatted_address;
            this.appointment.addressURL = place.url;
        } else
			this.appointment.address = values.addressCtrl;
		this.appointment.buildingType = values.buildingTypeCtrl;
		this.appointment.referralSource = values.referralSourceCtrl;
		this.appointment.sqFootage = values.sqFootageCtrl;
		this.appointment.listPrice = values.listPriceCtrl;
		this.appointment.lockbox = values.lockboxCtrl;
		this.appointment.clientName = values.clientFullNameCtrl;
		this.appointment.clientEmail = values.clientEmailCtrl;
		this.appointment.clientPhone = values.clientPhoneNumCtrl;
		this.appointment.agent = this.selectedAgentId;
		if (values.billToCtrl === "Other") {
            this.appointment.billToName = values.billToNameCtrl;
            this.appointment.billToEmail = values.billToEmailCtrl;
        }
		this.appointment.billTo = values.billToCtrl;
		if (parseFloat(values.quotedPriceCtrl)) this.appointment.quotedPrice = parseFloat(values.quotedPriceCtrl);
		if (parseFloat(values.quotedPriceCtrl)) this.appointment.adjustment = parseFloat(values.adjustmentCtrl);
		if (parseFloat(values.quotedPriceCtrl)) this.appointment.tax = this.hstVal;
		if (parseFloat(values.quotedPriceCtrl)) this.appointment.total = this.totalVal;
		this.appointment.basePrem = values.basePremCtrl ? values.basePremCtrl : 0;
		this.appointment.sizePrem = values.sizePremCtrl ? values.sizePremCtrl : 0;
		this.appointment.travelPrem = values.travelPremCtrl ? values.travelPremCtrl : 0;
		this.appointment.holidayPrem = values.holidayPremCtrl ? values.holidayPremCtrl : 0;
		this.appointment.miscPrem = values.miscPremCtrl ? values.miscPremCtrl : 0;
		this.appointment.paymentDetails = values.paymentDetailsCtrl;
		this.appointment.paid = this.paidCtrl;
		this.appointment.disclaimer = this.disclaimerCtrl;
		this.appointment.inspectorRequested = this.requestedCtrl;
		if (values.notesCtrl) this.appointment.notes = values.notesCtrl;
        this.appointment.invoiceDate = values.invoiceDateCtrl;
        this.appointment.paymentDate = values.paymentDateCtrl;
        this.appointment.paymentFor = values.paymentForCtrl;
        let reminderCtrls = [values.reminderDate2Ctrl, values.reminderDate3Ctrl];
        this.appointment.reminderDates = [values.reminderDate1Ctrl];
        reminderCtrls.forEach(x => {
           this.appointment.reminderDates.push(x);
        });
	}
}
