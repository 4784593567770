export class User {
	email: string;
	token: string;
	username: string;
	bio: string;
	image: string;
	inspectors: Array<{
		name: string,
		email: string
	}>;
	companies: Array<{
		name: string
	}>;
}
