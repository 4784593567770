import {ModuleWithProviders, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {RouterModule} from '@angular/router';
import {AppComponent} from './app.component';
import {AuthModule} from './auth/auth.module';
import {BookAppointmentModule} from './appointment/book-appointment.module';
import {AgentsModule} from './agents/agents.module';
import {HomeModule} from './home/home.module';
import {SettingsModule} from './settings/settings.module';
import {
	ApiService,
	AuthGuard,
	FooterComponent,
	HeaderComponent,
	JwtService,
	SharedModule,
	UserService,
	ConfigService
} from './shared';
import {AgmCoreModule} from "@agm/core";
import {AddressService} from "./shared/services/address.service";
import {MatNativeDateModule, MatSnackBarModule, MatDialogModule, MatMenuModule} from "@angular/material";
import {AppointmentService} from "./shared/services/appointment.service";
import {ErrorService} from "./shared/services/error.service";
import {StringService} from "./shared/services/string.service";
import {InfoDialogComponent} from "./infoDialog/info-dialog.component";
import {AddAgentsComponent} from "./agents/add-agents.component";
import {AgentService} from "./shared/services/agent.service";
import {AppointmentsModule} from "./appointments/appointments.module";
import {ConfirmComponent} from "./confirm/confirm.component";
import {ReportsComponent} from "./reports/reports.component";
import {ReportsModule} from "./reports/reports.module";
import {AddCompanyComponent} from "./settings/companies/add-company.component";
import {CompanyService, InspectorService} from "./shared/services";
import {AddInspectorComponent} from "./settings/inspectors/add-inspector.component";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {InvoiceComponent} from "./invoice/invoice.component";
import {InvoiceModule} from "./invoice/invoice.module";
import {UtilityService} from "./shared/services/utility.service";

const rootRouting: ModuleWithProviders = RouterModule.forRoot([], {useHash: true});

@NgModule({
	declarations: [
		AppComponent,
		FooterComponent,
		HeaderComponent,
		InfoDialogComponent,
        AddAgentsComponent,
        AddInspectorComponent,
		AddCompanyComponent,
		ConfirmComponent
	],
	imports: [
		BrowserModule,
		AuthModule,
		BookAppointmentModule,
		InvoiceModule,
		AppointmentsModule,
		ReportsModule,
		AgentsModule,
		HomeModule,
		rootRouting,
		SharedModule,
		SettingsModule,
		MatNativeDateModule,
		MatSnackBarModule,
        BrowserAnimationsModule,
        MatDialogModule
	],
	providers: [
        AddressService,
        AgentService,
		ApiService,
        AppointmentService,
		AuthGuard,
		CompanyService,
        InspectorService,
        ConfigService,
		JwtService,
		UserService,
		ErrorService,
		StringService,
        UtilityService
	],
	bootstrap: [AppComponent]
})
export class AppModule {
}
