import {AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {MatDialog, MatSnackBar} from "@angular/material";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {DataSource} from "@angular/cdk/table";
// import {Observable} from "rxjs/Rx";
import {Observable} from "rxjs/Observable";
import * as moment from "moment";
import {AppointmentService} from "../../shared/services/appointment.service";
import {date} from "ng2-validation/dist/date";
import {FormBuilder, FormControl, FormGroup} from "@angular/forms";
import {Appointment} from "../../shared/models/appointment.model";
import {StringService} from "../../shared/services/string.service";
import {ConfigService} from "../../shared/services/config.service";
import {CompanyService} from "../../shared/services";

@Component({
	selector: 'company-overview',
	templateUrl: './company-overview.component.html',
	styleUrls: ["./reports.styles.css"]
})
export class CompanyOverviewComponent implements OnInit {
	reportForm: FormGroup;
	startDateCtrl = new FormControl();
	endDateCtrl = new FormControl();
	inspectorNameCtrl = new FormControl();
	checked = false;
	appointments: any;
	inspectorTotal = 0;
	inspectionTypesResultsCurrent: any = {};
	inspectionTypesResultsPrevious: any = {};
	inspectionTypesResultsFull: any = {};
	overviewResultsCurrent: any;
	overviewResultsPrevious: any;
    companiesRes: any;
    companies: any;

	constructor(private appointmentService: AppointmentService,
				private snackBar: MatSnackBar,
				private stringService: StringService,
				private configService: ConfigService,
				private companyService: CompanyService,
				private fb: FormBuilder) {
		this.reportForm = this.fb.group({
			startDateCtrl: new Date(),
			endDateCtrl: new Date()
		})
	}

    ngOnInit() {
    }

	runReport() {
		let search = {
                startDate: new Date(this.reportForm.value.startDateCtrl.setHours(0,0,0,0)).toISOString(),
                endDate: new Date(this.reportForm.value.endDateCtrl.setHours(23,59,59,0)).toISOString(),
		    },
            start = new Date(search.startDate),
            end = new Date(search.endDate),
            searchPrev;
        start = new Date(start.setFullYear(start.getFullYear() - 1));
        end = new Date(end.setFullYear(end.getFullYear() - 1));
        searchPrev = {
            startDate: start,
            endDate: end
        };
		Observable.forkJoin(
            this.appointmentService.getCompanyOverview(search),
            this.appointmentService.getCompanyOverview(searchPrev)
        ).subscribe(results => {
			this.overviewResultsCurrent = results ? results[0] : [];
			this.overviewResultsPrevious = results ? results[1] : [];
			this.snackBar.open(this.stringService.reportRunSuccessfully, null, {duration: this.configService.getToastTimeout()});
		});
	}
}


