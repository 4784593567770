import {Injectable} from '@angular/core';
import {URLSearchParams} from '@angular/http';
import {Observable} from 'rxjs/Rx';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import {ApiService} from './api.service';
import {Appointment} from '../models';
import {Agent} from "../models/agent.model";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import set = Reflect.set;
import {Company} from "../models/company.model";

let params = new URLSearchParams();

@Injectable()
export class CompanyService {
	constructor(private apiService: ApiService) {
	}

	save(company): Observable<Company> {
        if (!company._id) {
            return this.apiService.post("/company/", {company: company})
                .map(data => data.company);
        } else {
            return this.apiService.put("/company/", {company: company})
                .map(data => data.company);
        }
	}


	get(): Observable<Company> {
		return this.apiService.get("/company/")
			.map(data => {
				return data.companies;
			});
	}

    getLogos(): Observable<Array<Company>> {
        return this.apiService.get("/company/logos")
            .map(data => {
                return data.companies;
            });
    }


}
