import {AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {MatDialog, MatSnackBar} from "@angular/material";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {DataSource} from "@angular/cdk/table";
// import {Observable} from "rxjs/Rx";
import {Observable} from "rxjs/Observable";
import * as moment from "moment";
import {AppointmentService} from "../../shared/services/appointment.service";
import {date} from "ng2-validation/dist/date";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {Appointment} from "../../shared/models/appointment.model";
import {ConfigService} from "app/shared";
import {StringService} from "../../shared/services/string.service";
import {AgentService} from "../../shared/services/agent.service";
import {UtilityService} from "../../shared/services/utility.service";

@Component({
	selector: 'appointments-by-agent',
	templateUrl: './appointments-by-agent.component.html',
	styleUrls: ["./reports.styles.css"]
})
export class AppointmentsByAgentComponent {
	reportForm: FormGroup;
	startDateCtrl = new FormControl();
	endDateCtrl = new FormControl();
	agentSearchCtrl = new FormControl();
	appointments: any;
	subtotal = 0;
	hst = 0;
	total = 0;
	wsib = 0;
	inspectionTypesTable = [];
	overviewResultsCurrent: any = {};
	overviewResultsPrevious: any = {};
	topAgents: any = [];
	selectedAgentId = undefined;
	agentResults: any = [];
	constructor(private appointmentService: AppointmentService,
				private agentService: AgentService,
				private snackBar: MatSnackBar,
				private stringService: StringService,
				private configService: ConfigService,
				private utilityService: UtilityService,
				private fb: FormBuilder) {
		this.reportForm = this.fb.group({
			startDateCtrl: new Date(),
			endDateCtrl: new Date(),
			agentSearchCtrl: ""
		})

		this.reportForm.get("agentSearchCtrl").valueChanges.subscribe(value => {
			if (value && this.selectedAgentId)
				this.reportForm.get("agentSearchCtrl").setValidators(Validators.required);
			else if (value !== undefined)
				this.reportForm.get("agentSearchCtrl").setErrors({"No realtor selected": "You must select a realtor."});

			this.agentResults = [];
			this.agentService.getByName(value).subscribe(agents => {
				if (!agents || Object.keys(agents).length === 0) return;
				var numResults = Math.min(Object.keys(agents).length, 5);
				for (var i = 0; i < numResults; i++) {
					this.agentResults.push(agents[i]);
				}
			})
		});
	}

	agentSelected(selected) {
		this.selectedAgentId = selected._id;
	}

	agentReset() {
		this.reportForm.get("agentSearchCtrl").enable();
		this.reportForm.get("agentSearchCtrl").setValue(undefined);
		this.selectedAgentId = undefined;
		this.reportForm.get("agentSearchCtrl").clearValidators();
		this.reportForm.get("agentSearchCtrl").setErrors([]);
		this.reportForm.get("agentSearchCtrl").updateValueAndValidity();
	}

	runReport() {
		const search = {
            startDate: new Date(this.reportForm.value.startDateCtrl.setHours(0,0,0,0)).toISOString(),
            endDate: new Date(this.reportForm.value.endDateCtrl.setHours(23,59,59,0)).toISOString(),
			agent: this.selectedAgentId
		};
		this.appointmentService.getAppointmentsByAgent(search).subscribe(values => {
			const appVals = ["appointmentDate", "address", "quotedPrice", "paymentDetails", "paid"];
			let csvContent = "",
				row = "",
				agent, currApp, i, j, k;
			this.appointments = values;
			if (this.appointments.length === 0) {
				this.snackBar.open(this.stringService.noResults, null, {duration: this.configService.getToastTimeout()});
				return;
			}
			csvContent += appVals.join(",") + "," + "\r\n";
			for (i = 0; i < this.appointments.length; i++) {
				row = "";
				currApp = this.appointments[i];
				currApp.appointmentDate = new Date(currApp.appointmentDate).toLocaleString();
				for (k = 0; k < appVals.length; k++) {
					if (currApp[appVals[k]])
						row += "\"" + currApp[appVals[k]] + "\"" + ",";
					else
						row += ",";
				}
				csvContent += row + "\r\n"; // add carriage return
			}
            this.utilityService.downloadCSV(csvContent, "appointments-by-agent");
            this.snackBar.open(this.stringService.reportDownloadedSuccessfully, null, {duration: this.configService.getToastTimeout()});
		})
	}
}


