import {Agent} from './agent.model';

export class Appointment {
	_id: string;
	company: string;
	inspector: string;
	appointmentDate: Date;
	inspectionType: string;
	address: string;
	addressURL: string;
	buildingType: string;
	referralSource: string;
	sqFootage: number;
	listPrice: number;
	lockbox: string;
	clientName: string;
	clientEmail: string;
	clientPhone: string;
	agent: Agent;
	billTo: billToTypes;
    billToName: string;
    billToEmail: string;
	quotedPrice: number;
	adjustment: number;
	tax: number;
	total: number;
	notes: string;
	basePrem: number;
	sizePrem: number;
	travelPrem: number;
	holidayPrem: number;
	miscPrem: number;
	createdAt: string;
	updatedAt: string;
	paid: boolean;
	disclaimer  : boolean;
	inspectorRequested: boolean;
	paymentDetails: string;
	invoiceDate: Date;
	reminderDates: [Date];
	paymentDate: Date;
	paymentFor: string;
}

enum billToTypes {
    "Agent",
    "Client",
    "Other"
}