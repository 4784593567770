import {Injectable} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {Headers, Http, RequestOptions} from "@angular/http";

@Injectable()

export class UtilityService {
	constructor(private http: Http) {
	}

	private setHeaders(): Headers {
		const headersConfig = {
			'Content-Type': 'application/json',
			'Accept': 'application/json'

		};

		return new Headers(headersConfig);
	}

	downloadCSV(csvString, filename): void {
        var blob = new Blob([csvString]),
            file = filename + ".csv";
        if (window.navigator.msSaveOrOpenBlob)  // IE hack; see http://msdn.microsoft.com/en-us/library/ie/hh779016.aspx
            window.navigator.msSaveBlob(blob, file);
        else
        {
            var a = window.document.createElement("a");
            // noinspection TypeScriptValidateTypes
            a.href = window.URL.createObjectURL(blob, {});
            a.download = file;
            document.body.appendChild(a);
            a.click();  // IE: "Access is denied"; see: https://connect.microsoft.com/IE/feedback/details/797361/ie-10-treats-blob-url-as-cross-origin-and-denies-access
            document.body.removeChild(a);
        }
	}
}
