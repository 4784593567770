import {Component, EventEmitter, Inject, OnInit, Output} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialog, MatDialogRef, MatSnackBar} from "@angular/material";
import {FormBuilder, FormControl, FormGroup, Validators} from "@angular/forms";
import {StringService} from "../../shared/services/string.service";
import {ConfigService} from "../../shared/services/config.service";
import {ErrorService} from "../../shared/services/error.service";
import {Company} from "../../shared/models/company.model";
import {CompanyService} from "../../shared/services/company.service";
import {InspectorService} from "../../shared/services";
import {Inspector} from "../../shared/models";

@Component({
	selector: 'add-inspector',
	templateUrl: './add-inspector.component.html',
	styleUrls: ["./inspectors-styles.css"]
})
export class AddInspectorComponent {
	inspector: Inspector = new Inspector();
	inspectorForm: FormGroup;
	nameCtrl = new FormControl();
	emailCtrl = new FormControl();
	title;
	buttonString;

	constructor(public dialogRef: MatDialogRef<AddInspectorComponent>,
				private fb: FormBuilder,
				private inspectorService: InspectorService,
				private stringService: StringService,
				public snackBar: MatSnackBar,
				private configService: ConfigService,
				private errorService: ErrorService,
				@Inject(MAT_DIALOG_DATA) public data: any) {
		this.inspectorForm = this.fb.group({
			nameCtrl: data ?  data.name : undefined,
            emailCtrl: [data && data.email ? data.email : undefined, [Validators.required, Validators.email]]
		});
		this.title = data ? "Edit Inspector" : "Add Inspector";
		this.buttonString = data ? "SAVE" : "ADD"
	}

	cancel() {
		this.dialogRef.close();
	}

	submitForm() {
		this.updateInspector(this.inspectorForm.value);
		this.inspectorService.save(this.inspector)
			.subscribe(
				res => {
					this.snackBar.open(this.stringService.inspectorSaved, null, {duration: this.configService.getToastTimeout()});
					this.dialogRef.close();
				},
				err => {
					this.errorService.showError(this.stringService.errorSavingInspector);
				}
			)
	}

    updateInspector(values: any) {
        if (this.data && this.data._id) this.inspector._id = this.data._id;
		this.inspector.name = values.nameCtrl;
		this.inspector.email = values.emailCtrl;
	}

}
