import {AfterViewInit, Component, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {MatDialog} from "@angular/material";
import {BehaviorSubject} from "rxjs/BehaviorSubject";
import {DataSource} from "@angular/cdk/table";
// import {Observable} from "rxjs/Rx";
import {Observable} from "rxjs/Observable";
import {AgentService} from "../shared/services/agent.service";
import {Agent} from "../shared/models/agent.model";
import {AgentData} from "../agents/agents.component";
import {AppointmentService} from "../shared/services/appointment.service";
import * as moment from "moment";
import {BookAppointmentComponent} from "../appointment/book-appointment.component";

@Component({
	selector: "appointments",
	templateUrl: './appointments.component.html',
	styleUrls: ["appointment.styles.css"]
})
export class AppointmentsComponent implements OnInit {
	displayedColumns = ["date", "inspector", "qPrice", "clientName", "agentName", "address"];
	appointmentDatabase = new AppointmentDatabase();
	dataSource: AppointmentDataSource | null;
	editAppointmentsDialog: any;
	@Input() search: string;
	dataChange: BehaviorSubject<AppointmentData[]> = new BehaviorSubject<AppointmentData[]>([]);
	datePicked;

	get data(): AppointmentData[] {
		return this.dataChange.value;
	}

	emptyAppointment = {
		company: undefined,
		inspector: undefined,
		appointmentDate: undefined,
		inspectionType: undefined,
		address: undefined,
		buildingType: undefined,
		referralSource: undefined,
		sqFootage: undefined,
		listPrice: undefined,
		lockbox: undefined,
		clientName: undefined,
		clientEmail: undefined,
		clientPhone: undefined,
		appointment: undefined,
		quotedPrice: undefined,
		tax: undefined,
		total: undefined,
		notes: undefined,
		createdAt: undefined,
		updatedAt: undefined,
		paymentDetails: undefined
	};

	constructor(public dialog: MatDialog,
				private appointmentService: AppointmentService) {
	}

	ngOnInit() {
		this.dataSource = new AppointmentDataSource(this);
		// TODO: Fix eventually. Without this line the table doesn't load until you click "Agents" a second time.
		this.searchAppointments();
	}

	searchAppointments() {
		var arr = [];
		if (!this.search)
			this.search = "";
		this.appointmentService.get(this.search).subscribe(val => {
			if (!val) return;
			for (var i = 0; i < Object.keys(val).length; i++) {
				val[i].appointmentDateDisplay = moment(val[i].appointmentDate).format("MMM Do YYYY, h:mm a");
				arr.push(val[i]);
			}
			this.dataChange.next(arr)
		});
	}

	searchByDate() {
		console.log(this.datePicked)
		var arr = [];
		if (!this.datePicked)
			return;
		this.appointmentService.getByDate(this.datePicked).subscribe(val => {
			console.log(val);
			if (!val) return;
			for (var i = 0; i < Object.keys(val).length; i++) {
				val[i].appointmentDateDisplay = moment(val[i].appointmentDate).format("MMM Do YYYY, h:mm a");
				arr.push(val[i]);
			}
			this.dataChange.next(arr)
		});
	}

	searchAppointmentsEnter() {
		if (!!this.search)
			this.searchAppointments();
	}

	clickAppointment(appointment) {
		this.editAppointmentsDialog = this.dialog.open(BookAppointmentComponent, {
			disableClose: true,
            data: appointment,
            panelClass: "appointment-dialog"
		});
		this.editAppointmentsDialog.afterClosed().subscribe(value => {
			this.searchAppointmentsEnter();
		})
	}
}

export interface AppointmentData {
	company: string;
	inspector: string;
	appointmentDate: Date;
	inspectionType: string;
	address: string;
	buildingType: string;
	referralSource: string;
	sqFootage: number;
	listPrice: number;
	lockbox: string;
	clientName: string;
	clientEmail: string;
	clientPhone: string;
	agent: Agent;
	quotedPrice: number;
	tax: number;
	total: number;
	notes: string;
	createdAt: string;
	updatedAt: string;
	emptyAppointment: string;
	paymentDetails: string;
}

export class AppointmentDatabase {
	/** Stream that emits whenever the data has been modified. */
	dataChange: BehaviorSubject<AppointmentData[]> = new BehaviorSubject<AppointmentData[]>([]);

	get data(): AppointmentData[] {
		return this.dataChange.value;
	}

	constructor() {
	}
}

export class AppointmentDataSource extends DataSource<any> {
	constructor(private _appointmentsComponent: AppointmentsComponent) {
		super();
	}

	/** Connect function called by the table to retrieve one stream containing the data to render. */
	connect(): Observable<AppointmentData[]> {
		return this._appointmentsComponent.dataChange;
	}

	disconnect() {
	}
}
