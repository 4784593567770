import {Injectable} from "@angular/core";
import {Observable} from "rxjs/Observable";
import {Headers, Http, RequestOptions} from "@angular/http";
import {MatSnackBar} from "@angular/material";
import {ConfigService} from "./config.service";

@Injectable()
export class StringService {
    tryAgain = " Please try again.";
	appointmentCreated = "The appointment was successfully created!";
	appointmentDeleted = "The appointment was successfully deleted!";
	appointmentEmailed = "The appointment was successfully emailed!";
    appointmentUpdated = "The appointment was successfully updated!";
    agentCreated = "The agent was successfully created!";
    agentSaved = "The agent was successfully saved!";
    companyCreated = "The company was successfully created!";
    companySaved = "The company was successfully saved!";
    errorCreatingAgent = "There was an error creating the agent." + this.tryAgain;
    errorCreatingAppointment = "There was an error creating the appointment." + this.tryAgain;
    errorDeletingAppointment = "There was an error deleting the appointment." + this.tryAgain;
    errorEmailingAppointment = "There was an error emailing the appointment." + this.tryAgain;
    errorSavingAgent = "There was an error saving the agent." + this.tryAgain;
    errorSavingCompany = "There was an error saving the company." + this.tryAgain;
    errorSavingInspector = "There was an error saving the inspector." + this.tryAgain;
    errorSavingPayroll = "There was an error saving the payroll." + this.tryAgain;
    errorUnexpected = "There was an unexpected error.";
    inspectorSaved = "The inspector was successfully saved!";
	noResults = "There are no results for this range.";
	payrollSaved = "Payroll information saved!";
	reportDownloadedSuccessfully = "The report was downloaded successfully!";
	reportRunSuccessfully = "The report was run successfully!";
	settingsSaved = "Your settings have been saved!";
	constructor() {}
}

