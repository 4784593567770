import {ModuleWithProviders, NgModule} from '@angular/core';
import {RouterModule} from '@angular/router';

import {AuthGuard, SharedModule} from '../shared';
import {errorComponent} from "../shared/error.component";
import {InfoDialogComponent} from "../infoDialog/info-dialog.component";
import {ReportsComponent} from "./reports.component";
import {Payroll} from "./inspectors/inspectors-reports.component";
import {
	MatCheckboxModule, MatExpansionModule
} from "@angular/material";
import {InspectorsOverviewComponent} from "./inspectors-overview/overview.component";
import {CompanyOverviewComponent} from "./company-overview/company-overview.component";
import {AgentOverviewComponent} from "./agent-overview/agent-overview.component";
import {AccountsReceivableComponent} from "./accounts-receivable/accounts-receivable.component";
import {AppointmentsByAgentComponent} from "./appointments-by-agent/appointments-by-agent.component";

const reportsRouting: ModuleWithProviders = RouterModule.forChild([
	{
		path: 'reports',
		component: ReportsComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'payroll',
		component: Payroll,
		canActivate: [AuthGuard]
	},
	{
		path: 'inspectors-overview',
		component: InspectorsOverviewComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'company-overview',
		component: CompanyOverviewComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'agent-overview',
		component: AgentOverviewComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'accounts-receivable',
		component: AccountsReceivableComponent,
		canActivate: [AuthGuard]
	},
	{
		path: 'appointments-by-agent',
		component: AppointmentsByAgentComponent,
		canActivate: [AuthGuard]
	}
]);

@NgModule({
	imports: [
		reportsRouting,
		SharedModule,
		MatExpansionModule,
		MatCheckboxModule
	],
	declarations: [
		ReportsComponent,
		Payroll,
		InspectorsOverviewComponent,
		CompanyOverviewComponent,
		AgentOverviewComponent,
		AccountsReceivableComponent,
		AppointmentsByAgentComponent
	],
	providers: []
})
export class ReportsModule {

}
